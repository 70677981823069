import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import SignInDisclaimer from 'components/Text/SignInDisclaimer';
import MainContainer from 'components/Container/MainContainer';
import { ResetPasswordOnProviderLink } from 'components/Link/ResetPasswordOnProviderLink';
import { getProviderFormLabel } from '../utils';
const RegisterJustworksCredential = ({
  provider,
  isLoading,
  onSubmit
}: RegisterImplementationProps) => <>
    <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => <>
          <MainContainer>
            <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Username" autoFocus />
            <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
            <ResetPasswordOnProviderLink href="https://login.justworks.com/" provider={provider.displayName} />
          </MainContainer>
          <SignInDisclaimer style={{
      marginBottom: '8px'
    }}>
            A third-party Admin/Accountant will be added.
          </SignInDisclaimer>
          <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit">
            Connect
          </LoaderButton>
        </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterJustworksCredential.tsx" />
  </>;
export default RegisterJustworksCredential;