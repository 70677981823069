import {
  ImplementationDetail,
  Response as ClientResponse,
} from '@finch-api/common/dist/internal/connect/validate';

export const shouldTriggerAuthFallback = (
  client?: ClientResponse,
  authFailures?: Record<string, number>,
  impl?: ImplementationDetail,
) => {
  if (!impl || !client || !authFailures) return false;
  const {
    id,
    acfFallbackEligible,
    attemptsBeforeFallback,
    authFallbackEnabled,
  } = impl;

  if (!acfFallbackEligible || !authFallbackEnabled) return false;

  if (client.settings.authFallbackEligible && attemptsBeforeFallback === 0)
    return true;

  if (client.settings.authFallbackEligible)
    return authFailures[id] >= attemptsBeforeFallback;

  return false;
};
