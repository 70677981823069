import styled, { keyframes } from 'styled-components';
import COLORS from 'constants/colors';
import { DetailedHTMLProps } from 'react';
interface LoadingProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  color: string;
  isLoading: boolean;
  disabled: boolean;
}
const spin = keyframes`
  0% { transform: rotate(0deg); }
  90% {transform: rotate(360deg);}
  100% { transform: rotate(360deg); }
`;
const Loading = styled.div<LoadingProps>`
  position: absolute;
  right: 0%;
  border: 2px solid ${props => props.isLoading ? COLORS.WHITE : props.color};
  border-left: 2px solid ${props => props.color};
  border-radius: 50%;
  margin-right: 16px;
  width: 12px;
  height: 12px;
  animation: ${spin} 1s linear infinite;

  ${props => !props.isLoading && props.disabled && `border-color: ${COLORS.GRAY.GRAY_400};`}
`;
Loading.defaultProps = {};
export default Loading;