import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import { RegisterImplementationProps } from 'constants/types';
import SignInCredentialBase from '../SignInCredentialBase';
import WarningMessage from 'pages/Authorize/Messages/Warning';
import { getProviderFormLabel } from '../utils';
const RegisterADPRunCredential = ({
  provider,
  isLoading,
  onSubmit
}: RegisterImplementationProps) => <>
    <WarningMessage data-sentry-element="WarningMessage" data-sentry-source-file="RegisterADPRunCredential.tsx">
      You must log in with an <b>Owner</b> account.
    </WarningMessage>
    <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => <SignInCredentialBase username={username} password={password} pwreset={new URL('https://netsecure.adp.com/ilink/pub/smsess/v3/forgot/theme.jsp')} provider={provider} isLoading={isLoading} inputPlaceholder="User ID" />} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterADPRunCredential.tsx" />
  </>;
export default RegisterADPRunCredential;