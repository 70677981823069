import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { datadogRum } from '@datadog/browser-rum';
import { OAuthRedirect } from '../../OAuth/OAuthRedirect';
import { getBackendUrl } from '../../../../services/get-backend-url';
const BACKEND_ROUTE = getBackendUrl();
const FactorialHROauthCallback = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const oAuthState = {
    state: queryParams.get('state'),
    code: queryParams.get('code'),
    error: queryParams.get('error')
  };
  const sessionKey = oAuthState.state;
  if (sessionKey) {
    datadogRum.setUser({
      id: sessionKey
    });
    datadogRum.addAction('factorial_hr_oauth_callback', {
      oAuthState
    });
  }
  const {
    isLoading,
    error: postError
  } = useQuery({
    queryKey: ['factorial_hr_oauth_state_update', sessionKey],
    queryFn: async () => {
      const response = await axios.post(`${BACKEND_ROUTE}/auth/oauth/state/${sessionKey}`, {
        oAuthState
      });
      window.close();
      return response;
    },
    refetchOnWindowFocus: false,
    enabled: !!sessionKey
  });
  return <OAuthRedirect isLoading={isLoading} error={postError} data-sentry-element="OAuthRedirect" data-sentry-component="FactorialHROauthCallback" data-sentry-source-file="FactorialHROAuthCallback.tsx" />;
};
export default FactorialHROauthCallback;