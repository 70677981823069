export const paramClientId = 'client_id';
export const paramConnectionId = 'connection_id';
export const paramClientName = 'client_name';
export const paramRedirectUri = 'redirect_uri';
export const paramProducts = 'products';
export const paramState = 'state';
export const paramAppType = 'app_type';
export const paramCategory = 'category';
export const paramSessionId = 'session';
export const paramPayrollProvider = 'payroll_provider';
export const paramManual = 'manual';
export const paramSandbox = 'sandbox';
export const paramSdkVersion = 'sdk_version';
export const paramCode = 'code';
export const paramIdpRedirectUri = 'idp_redirect_uri';
export const paramOriginUrl = 'origin_url';
export const paramError = 'error';
/** The user of the SDK. This is used to store the referrer for postMessage purposes */
export const paramSdkHostUrl = 'sdk_host_url';

export type Param =
  | typeof paramClientId
  | typeof paramConnectionId
  | typeof paramClientName
  | typeof paramRedirectUri
  | typeof paramProducts
  | typeof paramState
  | typeof paramAppType
  | typeof paramCategory
  | typeof paramPayrollProvider
  | typeof paramManual
  | typeof paramSandbox
  | typeof paramSdkVersion
  | typeof paramCode
  | typeof paramOriginUrl
  | typeof paramSessionId
  | typeof paramError
  | typeof paramSdkHostUrl
  | typeof paramIdpRedirectUri;
