import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import { SubdomainInput } from 'components/Input/SubdomainInput';
import { apiTokenInstructions, getProviderFormLabel } from '../utils';
const SignInSageHRApi = ({
  provider,
  client,
  isLoading,
  onSubmit,
  instructionsLink
}: RegisterImplementationProps) => {
  const instructions = apiTokenInstructions(provider.displayName, client.name);
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} instructionsLink={instructionsLink} divider onSubmit={onSubmit} fieldNames={['instructionCheck', 'subdomain', 'apiToken']} render={({
    instructionCheck,
    subdomain,
    apiToken
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <SubdomainInput label="Subdomain" value={subdomain.value} onChange={event => subdomain.onChange(event.target.value)} invalid={subdomain.invalid} domain="sagehr.hr" />
              <Input id="apiToken" value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label="API Key" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !subdomain.value || !apiToken.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInSageHRApi" data-sentry-source-file="SignInSageHRApi.tsx" />;
};
export default SignInSageHRApi;