import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
import { getProviderFormLabel } from '../utils';
const RegisterAppliedBusinessSolutionsApi = ({
  provider,
  isLoading,
  onSubmit
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>Enter your 6 digit Applied Business Solutions client ID</>, <>
      Your account&apos;s connection will not be complete until Applied Business
      Solutions completes its setup. Please allow 7 business days for this.
    </>];
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterAppliedBusinessSolutionsApi.tsx">
        To connect your Applied Business Solutions account you must enter your 6
        digit client ID
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterAppliedBusinessSolutionsApi.tsx" />
      <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['companyId']} render={({
      companyId
    }) => <>
            <MainContainer>
              <Input id="companyId" value={companyId.value} onChange={event => companyId.onChange(event.target.value)} invalid={companyId.invalid} label="Client ID" autoFocus />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterAppliedBusinessSolutionsApi.tsx" />
    </>;
};
export default RegisterAppliedBusinessSolutionsApi;