import { useState } from 'react';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import styled from 'styled-components';
import { Response as ValidateResponse } from '@finch-api/common/dist/internal/connect/validate';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { useOAuthStatePolling } from '../../OAuth/useOAuthStatePolling';
import FONTS from '../../../../constants/fonts';
import COLORS from '../../../../constants/colors';
import { SwitchImplementationButton } from '../../../../components/LinkButton/LinkButton';
import { replaceTemplateString } from '../../../../utils/replace-string';

// Replace with an https endpoint (ngrok endpoint potentially) to test locally
const RIPPLING_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/auth/rippling`;
const StatusMessage = styled.p`
  margin-top: 8px;
  font-size: ${FONTS.P};
  color: ${COLORS.GRAY.GRAY_600};
  text-align: center;
`;
const RegisterRipplingOAuth = ({
  client,
  provider,
  onSubmit,
  setError,
  sessionKey,
  onMockOAuth,
  handleClick,
  idpRedirect
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const onCodeReceived = async (clientCode: string) => {
    await onSubmit({
      providerRedirectUri: RIPPLING_REDIRECT_URI,
      clientCode
    });
  };
  const {
    loading,
    openOAuthWindow,
    statusMessage
  } = useOAuthStatePolling({
    sessionKey,
    onCodeReceived,
    setError
  });
  const getOAuthUrl = (config: ValidateResponse['providersConfig'][number]['oauthCredentials']) => {
    if (!config || !config.authorizeUrl || !config.clientId) {
      throw new Error('Invalid OAuth config');
    }
    const url = new URL(replaceTemplateString(config.authorizeUrl, client.manualConfig));
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('client_id', config.clientId);
    url.searchParams.set('redirect_uri', RIPPLING_REDIRECT_URI);
    url.searchParams.set('state', JSON.stringify({
      sessionKey
    }));
    return url.toString();
  };
  const onOAuthClick = async () => {
    setButtonLoading(true);
    const didMockOAuth = await onMockOAuth();
    if (didMockOAuth) {
      setButtonLoading(false);
      return;
    }
    openOAuthWindow(getOAuthUrl(provider.oauthCredentials));
    setButtonLoading(false);
  };
  const onIdpRedirectClick = () => {
    return onSubmit({
      idpRedirect
    });
  };
  return <>
      {loading ? <>
          <InstructionHeader>
            Rippling authentication will continue in a new window. Please make
            sure to allow popups and finish authenticating through Rippling in
            the new window
          </InstructionHeader>
        </> : <LoaderButton isLoading={loading || buttonLoading} disabled={loading || buttonLoading} onClick={idpRedirect ? onIdpRedirectClick : onOAuthClick}>
          Connect
        </LoaderButton>}
      {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}
      {handleClick && <SwitchImplementationButton onClick={handleClick}>
          Enter an API Token instead
        </SwitchImplementationButton>}
    </>;
};
export default withAuthorizeContext(RegisterRipplingOAuth);