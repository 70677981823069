import { Box, BoxProps } from '@chakra-ui/react';
import { CloseButton } from 'components/Button/CloseButton';
import { useAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
export const ContainerOverlay = ({
  children,
  isExitIntent = false,
  ...props
}: BoxProps & {
  isExitIntent?: boolean;
}) => {
  const {
    appType
  } = useAuthorizeContext() || {};
  return <Box pos="absolute" top="0" left="0" right="0" bottom="0" h="100%" w="100%" bg="white" zIndex={isExitIntent ? '2000' : '1000'} {...props} data-sentry-element="Box" data-sentry-component="ContainerOverlay" data-sentry-source-file="ContainerOverlay.tsx">
      {!isExitIntent && appType === 'spa' && <CloseButton />}

      {children}
    </Box>;
};