import GenericError from './GenericError';
export const ClientBlocked = ({
  clientName
}: {
  clientName?: string;
}) => {
  const errorMessage = clientName ? `Please reach out to ${clientName} for more information.` : "Please reach out to the application you're integrating with for more information.";
  return <GenericError title="Something went wrong" showFinchLogo={false} data-sentry-element="GenericError" data-sentry-component="ClientBlocked" data-sentry-source-file="ClientBlocked.tsx">
      <p>{errorMessage}</p>
    </GenericError>;
};