export const replaceTemplateString = (
  template: string,
  config: Record<string, string> | null,
): string => {
  return template.replace(/\{\{(\w+)\}\}/g, (match, key) => {
    if (config && `${key}` in config) {
      return config[key];
    }
    return match; // Return the original match if no corresponding key is found in the config
  });
};
