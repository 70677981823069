import _ from 'lodash';
import { useInstructions } from 'components/Instruction/Instructions';
import { RegisterImplementationProps } from 'constants/types';
import SignInApiBase from '../SignInApiBase';
import { SwitchImplementationButton } from '../../../../components/LinkButton/LinkButton';
const ripplingProductToPermissionMapping = {
  benefits: ['Deduction Permissions'],
  company: ['Company Permissions'],
  deduction: ['Deduction Permissions'],
  directory: ['Employee Permissions'],
  employee: ['Employee Permissions'],
  individual: ['Employee Permissions'],
  pay_statement: ['Employee Permissions'],
  payment: ['Employee Permissions']
};
const RegisterRipplingApi = ({
  client,
  provider,
  products,
  isLoading,
  onSubmit,
  handleClick
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const hasPayProducts = products.includes('payment') || products.includes('pay_statement');
  const tokenName = hasPayProducts ? 'Payroll' : 'Census';
  const requiredRipplingAPITokenPermissions = Array.from(new Set([...products, 'company'] // we will always require company permissions so we have an id for our db
  .map(product => _.get(ripplingProductToPermissionMapping, [product])).filter(Boolean).flat()));
  const requiredRipplingAPITokenPermissionsString = requiredRipplingAPITokenPermissions.length === 1 ? requiredRipplingAPITokenPermissions[0] : `${requiredRipplingAPITokenPermissions.slice(0, -1).join(', ')} and ${requiredRipplingAPITokenPermissions.slice(-1)}`;
  const instructions: JSX.Element[] = [<>
      Make sure you are on Rippling’s API Key package. If you need to upgrade,
      contact a Sales representative and let them know you are requesting an API
      token for internal use.
    </>, <>
      With API support enabled, navigate to the <b>Settings</b> sidebar -&gt;{' '}
      <b>Company Settings</b> app -&gt; <b>API Access</b> tab. Click{' '}
      <b>Create API Key</b> in the top right.
    </>, <>
      Click on <b>All</b> for {requiredRipplingAPITokenPermissionsString}. For
      identification purposes, please name the token <b>{tokenName}</b>. Click{' '}
      <b>Save</b> and copy the token into the field below.
    </>];
  return <>
      <SignInApiBase provider={provider} isLoading={isLoading} onSubmit={onSubmit} isAccepted={isAccepted} instructions={instructions} inputPlaceholder="API Key" header={<>
            To connect your Rippling account, you must create an API token for{' '}
            <strong>{client.name}</strong>.
          </>} notice="Rippling considers their API keys an added service and may require an additional fee to obtain one." data-sentry-element="SignInApiBase" data-sentry-source-file="RegisterRipplingApi.tsx" />
      {handleClick && <SwitchImplementationButton onClick={handleClick}>
          Authenticate through Rippling
        </SwitchImplementationButton>}
    </>;
};
export default RegisterRipplingApi;