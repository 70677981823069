import { create } from 'zustand';

export const usePopOutInstructionsLink = create<{
  popOutInstructionsLink: string | null;
  setPopOutInstructionsLink: (link: string | null) => void;
}>((set) => ({
  popOutInstructionsLink: null,
  setPopOutInstructionsLink: (link: string | null) =>
    set({ popOutInstructionsLink: link }),
}));
