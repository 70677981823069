import Header from 'components/Header/Header';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import LoaderButton from 'components/Button/LoaderButton';
import MainContainer from 'components/Container/MainContainer';
interface RegisterManualAdapterProps {
  onNextStep: (fieldToValueMap: Record<string, string>) => void;
  isLoading: boolean;
  contactEmailInContext: string | undefined;
  companyNameInContext: string | undefined;
}
const RegisterManualAdapter = ({
  onNextStep,
  contactEmailInContext,
  companyNameInContext,
  isLoading
}: RegisterManualAdapterProps) => {
  // only show the field that's not in context
  const fields: string[] = [];
  if (!contactEmailInContext) fields.push('contactEmail');
  if (!companyNameInContext) fields.push('companyName');
  return <>
      <Header data-sentry-element="Header" data-sentry-source-file="RegisterManualAdapter.tsx">Please provide your work email</Header>
      <MultiValueForm formLabel="Manual Sign In Form" onSubmit={onNextStep} fieldNames={fields} render={fields => <>
            <MainContainer>
              {!companyNameInContext && <Input id="companyName" value={fields.companyName.value} onChange={event => fields.companyName.onChange(event.target.value)} invalid={fields.companyName.invalid} label="Company name" autoFocus />}
              {!contactEmailInContext && <Input id="contactEmail" value={fields.contactEmail.value} onChange={event => fields.contactEmail.onChange(event.target.value)} invalid={fields.contactEmail.invalid} label="Contact email" autoFocus={!!companyNameInContext} />}
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit">
              Next Step
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterManualAdapter.tsx" />
    </>;
};
export default RegisterManualAdapter;