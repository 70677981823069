export const unleashConfig = {
  url:
    process.env.NODE_ENV === 'production'
      ? 'https://flags.tryfinch.com/proxy'
      : 'https://flags.stg.tryfinch.com/proxy',
  clientKey:
    process.env.NODE_ENV === 'production'
      ? '*:production.84ebbeef607572b9db00ca46eee543ddbb7940103f8c4a68b52a83bc'
      : '*:development.ddf8be7a005637a43d623dbe460bdbf410c0c82c24b81d5a7773739c',
  refreshInterval: 60,
  appName: 'connect',
};
