import { useMemo } from 'react';
import lock from 'assets/img/permissions/lock.svg';
import eye from 'assets/img/permissions/eye.svg';
import connect from 'assets/img/permissions/connect.svg';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import LoadingSpinner from 'pages/Authorize/LoadingSpinner';
import { FINCH_BASE_URL } from 'constants/urls';
import styles from './Preamble.module.scss';
import { AvatarGroup, FinchAvatar, LetterAvatar } from 'components/Avatar';
import { AppLink } from 'components/Link/AppLink';
import { Separator, Stack } from '@chakra-ui/react';
import { AppShellButton } from 'components/Button/AppShellButton';
import { AppHeading } from 'components/Text/AppHeading';
const Preamble = ({
  nextStep,
  client
}: AuthorizeContextValue) => {
  const forEmployersUrl = useMemo(() => {
    const url = new URL(`${FINCH_BASE_URL}/for-employers`);
    url.searchParams.set('utm_source', 'finch_connect');
    url.searchParams.set('utm_medium', 'product');
    url.searchParams.set('utm_campaign', 'for_employers');
    return url;
  }, []);
  return <div className={styles.container} data-sentry-component="Preamble" data-sentry-source-file="Preamble.tsx">
      {!client && <LoadingSpinner />}
      {client && <>
          <Stack gap="6">
            <div className={styles.header}>
              <AvatarGroup>
                <LetterAvatar letter={client.name[0]} />
                <FinchAvatar />
              </AvatarGroup>
              <AppHeading>
                {client.name} uses Finch to connect your payroll and HR systems.
              </AppHeading>
            </div>
            <Stack gap="6" align="center">
              <Separator />
              <div className={styles.bulletGroup}>
                <div className={styles.iconGroup}>
                  <img src={lock} alt="" />
                </div>
                <div className={styles.textGroup}>
                  <div className={styles.textHeader}>Trusted & secure</div>
                  <div className={styles.subheader}>
                    Your information is handled securely with end-to-end
                    encryption. Your credentials are not shared with{' '}
                    {client ? client.name : 'anyone'}.
                  </div>
                </div>
              </div>
              <div className={styles.bulletGroup}>
                <div className={styles.iconGroup}>
                  <img src={eye} alt="" />
                </div>
                <div className={styles.textGroup}>
                  <div className={styles.textHeader}>Privacy first</div>
                  <div className={styles.subheader}>
                    You can review a list of permissions before granting{' '}
                    {client.name} access to your system.
                  </div>
                </div>
              </div>
              <div className={styles.bulletGroup}>
                <div className={styles.iconGroup}>
                  <img src={connect} alt="" />
                </div>
                <div className={styles.textGroup}>
                  <div className={styles.textHeader}>Data portability</div>
                  <div className={styles.subheader}>
                    Finch does not sell your employer and employee data, and
                    will only use it with your permission.
                  </div>
                </div>
              </div>
              <Separator />
              <AppLink fontSize="xs" variant="plain" href={forEmployersUrl.toString()} target="_blank" rel="noopener noreferrer" textAlign="center">
                Learn more about Finch
              </AppLink>
              <div className={styles.privacyPrompt}>
                By clicking &quot;Continue&quot; you agree to Finch&apos;s{' '}
                <AppLink variant="plain" href={`${FINCH_BASE_URL}/legal/terms-of-service-for-end-users`} target="_blank" rel="noopener noreferrer">
                  Terms of Service
                </AppLink>{' '}
                and{' '}
                <AppLink variant="plain" href={`${FINCH_BASE_URL}/legal/privacy`} target="_blank" rel="noopener noreferrer">
                  End User Privacy Policy
                </AppLink>
                .
              </div>
            </Stack>
          </Stack>

          <AppShellButton type="submit" onClick={nextStep}>
            Continue
          </AppShellButton>
        </>}
    </div>;
};
export default withAuthorizeContext(Preamble);