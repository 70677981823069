import { RegisterImplementationProps } from 'constants/types';
import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import MainContainer from 'components/Container/MainContainer';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import LoaderButton from 'components/Button/LoaderButton';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import { AppLink } from 'components/Link/AppLink';
import { getProviderFormLabel } from '../utils';
const RegisterWorkdayApiToken = ({
  provider,
  isLoading,
  onSubmit,
  handleClick
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      Please follow the instructions{' '}
      <AppLink target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26242318065684-Workday-Add-an-Integration-System-User" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      to set up a new Integration System User with API access
    </>, <>
      Once you have set up an Integration System User, return to this page and
      input the information below.
    </>];
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterWorkdayApiToken.tsx">
        To connect your Workday account, you must create an Integration System
        User and configure it to access the Workday API
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterWorkdayApiToken.tsx" />
      <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['username', 'password', 'tenantId', 'apiBaseUrl']} render={({
      username,
      password,
      tenantId,
      apiBaseUrl
    }) => <>
            <MainContainer>
              <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Username" />
              <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
              <Input id="tenantId" value={tenantId.value} onChange={event => tenantId.onChange(event.target.value)} invalid={tenantId.invalid} label="Tenant ID" />
              <Input id="apiBaseUrl" value={apiBaseUrl.value} onChange={event => apiBaseUrl.onChange(event.target.value)} invalid={apiBaseUrl.invalid} label="API Base URL" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterWorkdayApiToken.tsx" />
      {handleClick && <SwitchImplementationButton onClick={handleClick}>
          Connect using a custom Workday report
        </SwitchImplementationButton>}
    </>;
};
export default RegisterWorkdayApiToken;