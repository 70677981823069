import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import MainContainer from 'components/Container/MainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import SignInDisclaimer from 'components/Text/SignInDisclaimer';
import { ProviderConfig } from '@finch-api/common/dist/internal/connect/validate';
type SignInCredentialBaseProps = {
  username: any;
  password: any;
  /**
   * A link to the provider's password reset screen, if one exists or is linkable
   */
  pwreset?: URL;
  provider: ProviderConfig;
  isLoading: boolean;
  /** @default 'A third-party administrator may be added.'*/
  disclaimer?: string;
  /** @default 'Email Address'*/
  inputPlaceholder?: string;
};
export default function SignInCredentialBase(props: SignInCredentialBaseProps): JSX.Element {
  const {
    username,
    password,
    pwreset,
    provider,
    isLoading,
    disclaimer: disclaimerOverride,
    inputPlaceholder: inputPlaceholderOverride
  } = props;
  const disclaimer = disclaimerOverride || 'A third-party administrator may be added.';
  const inputPlaceholder = inputPlaceholderOverride || 'Email Address';
  const forgotpassword = pwreset ? {
    uri: pwreset,
    providerDisplayName: provider.displayName
  } : undefined;
  return <>
      <MainContainer data-sentry-element="MainContainer" data-sentry-source-file="SignInCredentialBase.tsx">
        <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label={inputPlaceholder} autoFocus data-sentry-element="Input" data-sentry-source-file="SignInCredentialBase.tsx" />
        <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" reset={forgotpassword} data-sentry-element="PasswordInput" data-sentry-source-file="SignInCredentialBase.tsx" />
      </MainContainer>
      <SignInDisclaimer style={{
      marginBottom: '8px'
    }} data-sentry-element="SignInDisclaimer" data-sentry-source-file="SignInCredentialBase.tsx">
        {disclaimer}
      </SignInDisclaimer>
      <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit" data-sentry-element="LoaderButton" data-sentry-source-file="SignInCredentialBase.tsx">
        Connect
      </LoaderButton>
    </>;
}