import finchLogoLarge from 'assets/img/finchLogoLarge.svg';
import invalidCircleIcon from 'assets/img/invalidCircle.svg';
import { AppShellButton } from 'components/Button/AppShellButton';
import { InstructionCallout } from 'components/Instruction/InstructionCallout';
import { ReactNode } from 'react';
import { ContentContainer, ErrorCode, SandboxContainer, TextGroup, TitleText } from './styles';
const GenericError = ({
  buttonLabel,
  errorCode,
  title,
  showFinchLogo = true,
  children,
  onClick,
  isSecondaryButton = false,
  instructionsLink
}: {
  buttonLabel?: string;
  errorCode?: string;
  title: string;
  showFinchLogo?: boolean;
  children: ReactNode;
  isSecondaryButton?: boolean;
  onClick?: () => void;
  instructionsLink?: string;
}) => {
  return <SandboxContainer data-sentry-element="SandboxContainer" data-sentry-component="GenericError" data-sentry-source-file="GenericError.tsx">
      {showFinchLogo && <img alt="finch-logo" src={finchLogoLarge} width={'65px'} />}
      <ContentContainer data-sentry-element="ContentContainer" data-sentry-source-file="GenericError.tsx">
        <img alt="invalid-circle" src={invalidCircleIcon} />
        <TextGroup data-sentry-element="TextGroup" data-sentry-source-file="GenericError.tsx">
          <TitleText data-sentry-element="TitleText" data-sentry-source-file="GenericError.tsx">{title}</TitleText>
          <div>{children}</div>
        </TextGroup>
        {errorCode && <ErrorCode>Error Code: {errorCode}</ErrorCode>}
        {instructionsLink && <InstructionCallout link={instructionsLink} checkPermissions />}
      </ContentContainer>
      {buttonLabel && <AppShellButton variant={isSecondaryButton ? 'outline' : 'solid'} onClick={onClick ? onClick : () => {
      window.location.reload();
    }}>
          {buttonLabel}
        </AppShellButton>}
    </SandboxContainer>;
};
export default GenericError;