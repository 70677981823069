import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import LoaderButton from 'components/Button/LoaderButton';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
import { AppLink } from 'components/Link/AppLink';
import { getProviderFormLabel } from '../utils';
const PAYMENT_PRODUCT = 'payment';
const PAY_STATEMENT_PRODUCT = 'pay_statement';
const RegisterInsperityApi = ({
  client,
  provider,
  products,
  isLoading,
  onSubmit,
  handleClick
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const hasPayScope = products.includes(PAYMENT_PRODUCT) || products.includes(PAY_STATEMENT_PRODUCT);
  const referenceDoc = hasPayScope ? 'https://developer.tryfinch.com/employer-resources/Insperity#census-and-pay-data' : 'https://developer.tryfinch.com/employer-resources/Insperity#census-data-only';
  const instructions: JSX.Element[] = [<>
      Please follow the instructions{' '}
      <AppLink target="_blank" href={referenceDoc} rel="noopener noreferrer">
        here
      </AppLink>{' '}
      to get credentials. Once you have received the API token, return to this
      page and input the information below.
    </>];
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterInsperityAPI.tsx">
        To connect your Insperity account, you must create an API token for{' '}
        <strong>{client.name}</strong>.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterInsperityAPI.tsx" />
      <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['companyId', 'apiToken']} render={({
      companyId,
      apiToken
    }) => <>
            <MainContainer>
              <Input id="companyId" value={companyId.value} onChange={event => companyId.onChange(event.target.value)} invalid={companyId.invalid} label="Company ID" />
              <Input id="apiToken" value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label="API Token" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterInsperityAPI.tsx" />
      {handleClick && <SwitchImplementationButton onClick={handleClick}>
          Use a Username and Password instead
        </SwitchImplementationButton>}
    </>;
};
export default RegisterInsperityApi;