import Input, { InputProps } from './Input';
import { ResetPasswordOnProviderLink } from 'components/Link/ResetPasswordOnProviderLink';
type PasswordResetProps = {
  reset?: {
    uri: URL;
    providerDisplayName: string;
  };
};
const PasswordInput = ({
  reset,
  id = 'password',
  label = 'Password',
  ...props
}: InputProps & PasswordResetProps) => <div data-sentry-component="PasswordInput" data-sentry-source-file="PasswordInput.tsx">
    <Input type="password" id={id} label={label} {...props} data-sentry-element="Input" data-sentry-source-file="PasswordInput.tsx" />
    {reset && <ResetPasswordOnProviderLink href={reset.uri.toString()} provider={reset.providerDisplayName} />}
  </div>;
PasswordInput.defaultProps = {
  type: 'password',
  id: 'password',
  label: 'Password'
};
export default PasswordInput;