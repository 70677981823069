import { create } from 'zustand';
import { usePopOutInstructionsLink } from './pop-out-instructions';

export const useConfirmClose = create<{
  confirmClose: boolean;
  setConfirmClose: (confirmClose: boolean) => void;
}>((set) => ({
  confirmClose: false,
  setConfirmClose: (confirmClose: boolean) => set({ confirmClose }),
}));

export const useShowConfirmClose = () => {
  const setPopOutInstructionsLink = usePopOutInstructionsLink(
    (state) => state.setPopOutInstructionsLink,
  );
  const { confirmClose, setConfirmClose } = useConfirmClose();

  const setConfirmCloseTrue = () => {
    setPopOutInstructionsLink(null);
    setConfirmClose(true);
  };

  const setConfirmCloseFalse = () => {
    setConfirmClose(false);
  };

  return { setConfirmCloseTrue, setConfirmCloseFalse, confirmClose };
};
