import { Link, LinkProps } from '@chakra-ui/react';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlag } from 'constants/feature-flags';
import { usePopOutInstructionsLink } from 'store/pop-out-instructions';
export const AppLink = (props: LinkProps & {
  showInFrame?: boolean;
}) => {
  const setPopOutInstructionsLink = usePopOutInstructionsLink(state => state.setPopOutInstructionsLink);
  const popOutInstructionsEnabled = useFlag(FeatureFlag.PopOutInstructionsEnabled);
  return props.showInFrame && popOutInstructionsEnabled ? <Link fontWeight="600" variant="underline" onClick={() => setPopOutInstructionsLink(props.href || null)} outline="none" {...props} target="_self" href="#" /> : <Link fontWeight="600" variant="underline" outline="none" {...props} />;
};