import { AppIconButton } from './AppIconButton';
import { CloseIcon } from 'components/Icons/CloseIcon';
import { usePopOutInstructionsLink } from 'store/pop-out-instructions';
import { useConfirmClose } from 'store/confirm-close';
export const CloseButton = () => {
  const setPopOutInstructionsLink = usePopOutInstructionsLink(state => state.setPopOutInstructionsLink);
  const setConfirmClose = useConfirmClose(state => state.setConfirmClose);
  const onClick = () => {
    setPopOutInstructionsLink(null);
    setConfirmClose(true);
  };
  return <AppIconButton aria-label="Close" pos="absolute" top="16px" left="calc(400px - 48px)" onClick={onClick} zIndex="100" data-sentry-element="AppIconButton" data-sentry-component="CloseButton" data-sentry-source-file="CloseButton.tsx">
      <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="CloseButton.tsx" />
    </AppIconButton>;
};