import { Sandbox } from '@finch-api/common/dist/internal/connect/authorize';
import axios from 'axios';
import { useAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { getBackendUrl } from './get-backend-url';

const BACKEND_ROUTE = getBackendUrl();

type RegisterStepOpts = {
  step: string;
  currentBrowserSessionKey: string;
  sessionKey: string;
  appType: string;
  sdkVersion?: string;
  clientId: string;
  sandbox: Sandbox;
  providerId: string;
  connectionId?: string;
  providerImplementationId?: string;
};

export const registerStep = async (opts: RegisterStepOpts) => {
  const response = await axios.post(
    `${BACKEND_ROUTE}/auth/register-step`,
    opts,
  );

  return response.data;
};

export const useRegisterStep = () => {
  const authorizeContext = useAuthorizeContext();

  if (!authorizeContext) {
    throw new Error('useRegisterStep must be used within an AuthorizeContext');
  }

  const {
    currentStep,
    currentBrowserSessionKey,
    sessionKey,
    appType,
    sdkVersion,
    connectionId,
    clientId,
    sandbox,
    providerId,
    providerImplementation,
  } = authorizeContext;

  return (opts?: Partial<RegisterStepOpts>) =>
    registerStep({
      step: currentStep,
      currentBrowserSessionKey: currentBrowserSessionKey,
      sessionKey: sessionKey,
      appType: appType,
      sdkVersion: sdkVersion,
      clientId,
      sandbox,
      providerId,
      providerImplementationId: providerImplementation?.id,
      connectionId: connectionId,
      ...opts,
    });
};
