export enum FinchErrorCode {
  // Errors users can resolve without leaving Connect
  InvalidApiToken = 'invalid_api_token',
  IncorrectPayrollProvider = 'incorrect_payroll_provider',
  InvalidMfaCode = 'invalid_mfa_code',
  IncompleteRequest = 'incomplete_request',
  InvalidLoginCredentials = 'invalid_login_credentials',

  // Errors users cannot resolve without leaving Connect
  ApiTokenExpired = 'api_token_expired',
  ApiTokenInsufficientPermissions = 'api_token_insufficient_permissions',
  AccountSetupRequired = 'account_setup_required',
  NoValidAccounts = 'no_valid_accounts',
  ExpiredPassword = 'expired_password',
  MfaAttemptsExceeded = 'mfa_attempts_exceeded',
  UserAccountSuspended = 'user_account_suspended',

  // A different user needs to authenticate
  AccountInsufficientPermissions = 'insufficient_account_permissions',

  // Intermittent errors
  FinchSideError = 'finch_side_error',
  ProviderSideError = 'provider_side_error',

  // Users will not be able to resolve these errors
  DeveloperSetupError = 'developer_setup_error',
  MiscellaneousError = 'miscellaneous_error',
  ProductNotSupported = 'product_not_supported',

  // Ungrouped errors
  SessionExpired = 'session_expired',
}

export const ERRORS_USERS_CAN_RESOLVE_WITHIN_CONNECT = [
  FinchErrorCode.InvalidApiToken,
  FinchErrorCode.IncorrectPayrollProvider,
  FinchErrorCode.InvalidMfaCode,
  FinchErrorCode.IncompleteRequest,
  FinchErrorCode.InvalidLoginCredentials,
];

export const ERRORS_USERS_NEEDS_TO_LEAVE_CONNECT_TO_SOLVE = [
  FinchErrorCode.ApiTokenExpired,
  FinchErrorCode.ApiTokenInsufficientPermissions,
  FinchErrorCode.AccountSetupRequired,
  FinchErrorCode.NoValidAccounts,
];

export const ERRORS_A_DIFFERENT_USER_NEEDS_TO_AUTHENTICATE = [
  FinchErrorCode.AccountInsufficientPermissions,
];

export const INTERMITTENT_ERRORS = [
  FinchErrorCode.FinchSideError,
  FinchErrorCode.ProviderSideError,
];

export const ERRORS_USERS_WILL_NOT_BE_ABLE_TO_RESOLVE = [
  FinchErrorCode.DeveloperSetupError,
  FinchErrorCode.MiscellaneousError,
  FinchErrorCode.ProductNotSupported,
];
