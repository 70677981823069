import { datadogRum } from '@datadog/browser-rum';
import * as amplitude from '@amplitude/analytics-browser';
import FlagProvider from '@unleash/proxy-client-react';
import posthog from 'posthog-js';
import './index.scss';
import * as Sentry from '@sentry/react';
import App from './App';
import buildInfo from './build-info.json';
import { unleashConfig } from './services/feature-flag';
import { DEVELOPMENT_AMPLITUDE_KEY, PRODUCTION_AMPLITUDE_KEY } from 'constants/amplitude';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SESSION_KEY } from 'utils/session';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, defaultSystem } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
const IS_PROD = process.env.NODE_ENV === 'production';
const beforeSendingSentryEvent = event => {
  event.tags = event.tags ?? {};
  event.tags.session_key = SESSION_KEY;
  return event;
};
Sentry.init({
  dsn: IS_PROD ? 'https://d7d7df76a78f4cb6a54c8a8334178e71@o412759.ingest.us.sentry.io/5292817' : undefined,
  environment: process.env.NODE_ENV ?? 'development',
  release: buildInfo?.gitSha,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false
  })],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api\.tryfinch\.com/, /^https:\/\/api\.prod\.tryfinch\.com/],
  beforeSendTransaction: beforeSendingSentryEvent,
  beforeSend: beforeSendingSentryEvent
});
if (IS_PROD) {
  datadogRum.init({
    applicationId: 'b2dfe768-2769-4946-8eff-8cefd5c65b8e',
    clientToken: 'pub48ee1f6dd1ed500020bf62c218aedb31',
    site: 'datadoghq.com',
    service: 'finch-connect',
    env: process.env.NODE_ENV,
    version: buildInfo?.gitSha,
    proxy: 'https://rum.tryfinch.com'
  });
}
amplitude.init(IS_PROD ? PRODUCTION_AMPLITUDE_KEY : DEVELOPMENT_AMPLITUDE_KEY, SESSION_KEY, {
  defaultTracking: true
});
if (IS_PROD) {
  posthog.init('phc_OJPuXLQrsOIjJtfOKQ8pZh1OAKpRRU19w15wSd2Y1h3', {
    api_host: `${window.location.origin}/ingest`
  });
}
const queryClient = new QueryClient();
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<StrictMode>
    <ChakraProvider value={defaultSystem}>
      <FlagProvider config={unleashConfig}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </FlagProvider>
    </ChakraProvider>
  </StrictMode>);