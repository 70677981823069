import styled from 'styled-components';
import finchLogo from 'assets/img/finchLogo.svg';
import { BaseAvatar } from './BaseAvatar';
const StyledAvatar = styled(BaseAvatar)`
  background-color: transparent;
`;
export const FinchAvatar = () => {
  return <StyledAvatar data-sentry-element="StyledAvatar" data-sentry-component="FinchAvatar" data-sentry-source-file="FinchAvatar.tsx">
      <img src={finchLogo} alt="Finch logo" />
    </StyledAvatar>;
};