import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import TokenInput from 'components/Input/TokenInput';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import { apiTokenInstructions, getProviderFormLabel } from '../utils';
const SignInHumaansApi = ({
  provider,
  client,
  isLoading,
  onSubmit,
  instructionsLink
}: RegisterImplementationProps) => {
  const instructions = apiTokenInstructions(provider.displayName, client.name);
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} instructionsLink={instructionsLink} divider onSubmit={onSubmit} fieldNames={['instructionCheck', 'apiToken']} render={({
    instructionCheck,
    apiToken
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <TokenInput value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !apiToken.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInHumaansApi" data-sentry-source-file="SignInHumaansApi.tsx" />;
};
export default SignInHumaansApi;