import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
import { getProviderFormLabel } from '../utils';
const RegisterCeridianDayforceApi = ({
  isLoading,
  onSubmit,
  provider
}: RegisterImplementationProps) => <>
    <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['clientCode', 'username', 'password']} render={({
    clientCode,
    username,
    password
  }) => <>
          <MainContainer>
            <Input id="client_code" value={clientCode.value} onChange={event => clientCode.onChange(event.target.value)} invalid={clientCode.invalid} label="Company" autoFocus />
            <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Username" />
            <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
          </MainContainer>
          <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit">
            Connect
          </LoaderButton>
        </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterCeridianDayforceApi.tsx" />
  </>;
export default RegisterCeridianDayforceApi;