const FONTS = {
  H1: '62px',
  H2: '52px',
  H3: '20px',
  H4: '16px',
  H5: '14px',
  P: '12px',
};

export default FONTS;
