import { useState } from 'react';
import _ from 'lodash';
import { authorize } from 'services/auth';
import { withAuthorizeContext, AuthorizeContextValue } from '../AuthorizeContext';
import LoaderButton from 'components/Button/LoaderButton';
import { getStatusCode } from '../SignIn/utils';
import { AuthProcessingResponse } from '@finch-api/common/dist/internal/connect/authorize';
import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import { PayrollProviderId } from 'constants/types';
import { ProviderAppShell } from '../ProviderAppShell';
import { FinchErrorCode } from 'constants/finch-error-code';
const payrollProviderInstructions: { [key in PayrollProviderId]?: (params: any) => JSX.Element[] } = {
  [PayrollProviderId.ONPAY]: ({
    securityCode,
    client
  }: {
    securityCode: string;
    client: {
      name: string;
    };
  }) => [<>
      You will receive an email from OnPay with a security code. Enter the code
      below to complete the challenge: <strong>{securityCode}</strong>
    </>, <>
      When finished, click continue to add <strong>{client.name}</strong> as a
      Third-Party Administrator to retrieve data.
    </>]
};

// Page for when users need to take external actions in order to authenticate
const SecurityChallenge = ({
  client,
  prevStep,
  providerImplementation,
  payrollProvider,
  error,
  setError,
  clientId,
  connectionId,
  clientName,
  redirectUri,
  products,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  sandbox,
  appType,
  implementationHasAssistedBenefits,
  handleAuthorizeResponse,
  setProviderToRedirect,
  securityCode
}: AuthorizeContextValue) => {
  const {
    isAccepted
  } = useInstructions();
  if (!client) throw new Error('no client');
  if (!payrollProvider) throw new Error('no payroll provider');
  if (!providerImplementation) throw new Error('no provider implementation');
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const getPayrollProviderSecurityChallengeInstructions = () => {
    const providerId = payrollProvider.id as PayrollProviderId;
    switch (providerId) {
      case PayrollProviderId.ONPAY:
        return payrollProviderInstructions?.onpay ? payrollProviderInstructions.onpay({
          securityCode,
          client
        }) : undefined;
      default:
        return undefined;
    }
  };
  const instructions = getPayrollProviderSecurityChallengeInstructions() ?? [<>
      You will need to complete an external action to finish your connection to{' '}
      <strong>{client.name}</strong>.
    </>];
  const onContinue = async () => {
    setLoading(true);
    setError(null);
    try {
      const authorizeResponse = (await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        sessionKey,
        currentBrowserSessionKey,
        state,
        category,
        step: 'SECURITY_CHALLENGE',
        payrollProviderId: payrollProvider.id,
        providerImplementationId: providerImplementation.id,
        sandbox,
        appType,
        implementationHasAssistedBenefits
      })) as AuthProcessingResponse;
      handleAuthorizeResponse(authorizeResponse);

      // If we are redirecting, we keep the loading state to avoid double submission.
      // Therefore, we only remove the loading state when there is no redirect.
      if (!('redirect' in authorizeResponse)) {
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      const finchCode = _.get(err, 'response.data.finch_code');
      if ([FinchErrorCode.AccountSetupRequired, FinchErrorCode.NoValidAccounts, FinchErrorCode.SessionExpired, FinchErrorCode.IncorrectPayrollProvider].includes(finchCode)) {
        setDisableSubmit(true);
      }
      if (finchCode === FinchErrorCode.IncorrectPayrollProvider) {
        const correctPayrollProvider = _.get(err, 'response.data.correct_payroll_provider');
        setProviderToRedirect(correctPayrollProvider);
      }
      if (err.message === 'Incorrect MFA code. Max attempts reached. Please try again from the beginning.') {
        setError({
          message: err.message,
          status: getStatusCode(err)
        });
        prevStep();
      }
      setError({
        message: err.message || 'Unexpected error has occurred. Please try again.',
        status: getStatusCode(err),
        finchCode
      });
    }
  };
  return <ProviderAppShell provider={payrollProvider} providerImplementation={providerImplementation} error={error} setError={setError} headerText={`Complete the security verification to continue`} goBack={prevStep} data-sentry-element="ProviderAppShell" data-sentry-component="SecurityChallenge" data-sentry-source-file="SecurityChallenge.tsx">
      <InstructionList instructions={instructions} color={payrollProvider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="SecurityChallenge.tsx" />
      <LoaderButton color={payrollProvider.primaryColor} isLoading={loading} disabled={loading || disableSubmit || !isAccepted} onClick={onContinue} data-sentry-element="LoaderButton" data-sentry-source-file="SecurityChallenge.tsx">
        Continue
      </LoaderButton>
    </ProviderAppShell>;
};
export default withAuthorizeContext(SecurityChallenge);