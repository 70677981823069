import {
  ImplementationDetail,
  ProviderImplementationStatus,
} from '@finch-api/common/dist/internal/connect/validate';

export const isImplementationInBeta = (
  implementationDetail: ImplementationDetail,
): boolean => {
  return [
    ProviderImplementationStatus.BETA,
    ProviderImplementationStatus.DEVELOPMENT,
  ].includes(implementationDetail.status);
};
