import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { getBackendUrl } from '../services/get-backend-url';
import { RedirectPage } from '../components/RedirectPage';
import { query } from '../utils/params';
export const CompleteSession = () => {
  const code = query('code');
  const {
    error,
    isLoading
  } = useQuery<null, AxiosError>({
    queryKey: ['completeSession', code],
    queryFn: () => axios.post(`${getBackendUrl()}/auth/complete`, {
      code
    }),
    refetchOnWindowFocus: false,
    retry: false
  });
  return <RedirectPage errorText={{
    header: 'Something went wrong!',
    message: 'There was an error completing the session. Please reach out to your administrator for help.'
  }} successText={{
    header: 'Success!',
    message: 'You have successfully completed your authorization. You can now close this window'
  }} error={error} isLoading={isLoading} data-sentry-element="RedirectPage" data-sentry-component="CompleteSession" data-sentry-source-file="CompleteSession.tsx" />;
};