import { ReactNode } from 'react';
import styled from 'styled-components';
import checkWithCircle from 'assets/icons/check_with_circle.svg';
import xIcon from 'assets/icons/close_icon_red.svg';
import styles from './Redirect.module.scss';
import authorizeStyles from '../../pages/Authorize/Authorize.module.scss';
import Container from '../Container/Container';
import LoadingSpinner from '../../pages/Authorize/LoadingSpinner';
const ErrorLogoCircle = styled.div`
  border-radius: 50%;
  background-color: #f8d7da;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const RedirectPage = ({
  isLoading = false,
  error,
  successText,
  errorText,
  logoSrc
}: {
  isLoading?: boolean;
  error?: unknown;
  successText: {
    header: ReactNode;
    message: ReactNode;
  };
  errorText: {
    header: ReactNode;
    message: ReactNode;
  };
  logoSrc?: string;
}) => {
  return <div className={authorizeStyles.outerContainer} data-sentry-component="RedirectPage" data-sentry-source-file="RedirectPage.tsx">
      <div className={authorizeStyles.container}>
        <Container data-sentry-element="Container" data-sentry-source-file="RedirectPage.tsx">
          {isLoading ? <LoadingSpinner /> : <>
              {logoSrc ? <img src={logoSrc} alt="Finch logo" className={styles.logo} /> : <div className={styles.logo} />}
              {error ? <>
                  <ErrorLogoCircle className={styles.checkLogo}>
                    <img src={xIcon} alt="Error logo" height="45px" width="45px" />
                  </ErrorLogoCircle>
                  <div className={styles.successHeader}>{errorText.header}</div>
                  <div className={styles.successMessage}>
                    {errorText.message}
                  </div>
                </> : <>
                  <img src={checkWithCircle} alt="Success logo" className={styles.checkLogo} />
                  <div className={styles.successHeader}>
                    {successText.header}
                  </div>
                  <div className={styles.successMessage}>
                    {successText.message}
                  </div>
                </>}
            </>}
        </Container>
      </div>
    </div>;
};