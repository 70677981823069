import { Center, HStack, Text } from '@chakra-ui/react';
import { QuestionMarkIcon } from 'components/Icons/QuestionMarkIcon';
import { AppLink } from 'components/Link/AppLink';
import COLORS from 'constants/colors';
export const InstructionCallout = ({
  link,
  checkPermissions
}: {
  link: string;
  checkPermissions?: boolean;
}) => {
  return <HStack align="flex-start" p="2" borderRadius="8px" bg={COLORS.GRAY.GRAY_200} color={COLORS.FINCH.BLACK} fontSize="14px" borderColor={COLORS.GRAY.GRAY_400} borderWidth="1px" gap="2" data-sentry-element="HStack" data-sentry-component="InstructionCallout" data-sentry-source-file="InstructionCallout.tsx">
      <Center boxSize="20px" data-sentry-element="Center" data-sentry-source-file="InstructionCallout.tsx">
        <QuestionMarkIcon data-sentry-element="QuestionMarkIcon" data-sentry-source-file="InstructionCallout.tsx" />
      </Center>
      <Text textAlign="left" data-sentry-element="Text" data-sentry-source-file="InstructionCallout.tsx">
        Need help? Follow{' '}
        <AppLink href={link} rel="noreferrer" target="_blank" showInFrame data-sentry-element="AppLink" data-sentry-source-file="InstructionCallout.tsx">
          Finch&apos;s instructions
        </AppLink>{' '}
        {checkPermissions ? 'to learn how to check your permissions.' : 'to complete these steps.'}
      </Text>
    </HStack>;
};