import { GoBackIcon } from 'components/Icons/GoBackIcon';
import { AppIconButton } from './AppIconButton';
export const GoBackButton = ({
  onClick
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return <AppIconButton aria-label="Go Back" pos="absolute" top="16px" left="24px" onClick={onClick} data-sentry-element="AppIconButton" data-sentry-component="GoBackButton" data-sentry-source-file="GoBackButton.tsx">
      <GoBackIcon data-sentry-element="GoBackIcon" data-sentry-source-file="GoBackButton.tsx" />
    </AppIconButton>;
};