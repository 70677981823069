import { Box } from '@chakra-ui/react';
import parse from 'html-react-parser';
import { AppLink } from 'components/Link/AppLink';
import { useEffect, useRef } from 'react';
const IframedContent = ({
  html
}: {
  html: string;
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;
    const doc = iframe.contentDocument;
    if (!doc) return;

    // Write the HTML content to the iframe
    doc.open();
    doc.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <base target="_blank" />
        </head>
        ${html}
      </html>
    `);
    doc.close();
  }, [html]);
  return <iframe ref={iframeRef} style={{
    border: 'none',
    width: '100%',
    height: '100%'
  }} title="Instructions Content" data-sentry-component="IframedContent" data-sentry-source-file="IsolatedContent.tsx" />;
};
export const IsolatedContent = ({
  html,
  renderInIframe
}: {
  html: string;
  renderInIframe?: boolean;
}) => {
  const options = {
    replace: (domNode: any) => {
      if (domNode.type === 'tag') {
        // Replace links
        if (domNode.name === 'a') {
          return <AppLink href={domNode.attribs.href} target="_blank" rel="noreferrer">
              {domNode.children[0].data}
            </AppLink>;
        }
      }
    }
  };
  if (renderInIframe) {
    return <IframedContent html={html} />;
  }
  return <Box h="full" title="Instructions Content" css={{
    '& h1': {
      fontSize: ['xl', '2xl'],
      fontWeight: 'bold',
      mb: 4
    },
    '& h2': {
      fontSize: ['lg', 'xl'],
      fontWeight: 'bold',
      mb: 3
    },
    '& p': {
      mb: 2,
      lineHeight: 'tall'
    },
    '& ul, ol': {
      ml: 6,
      mb: 4
    },
    '& li': {
      mb: 2
    },
    '& b, strong': {
      fontWeight: '700'
    },
    '& em': {
      fontStyle: 'italic'
    }
  }} data-sentry-element="Box" data-sentry-component="IsolatedContent" data-sentry-source-file="IsolatedContent.tsx">
      {parse(html, options)}
    </Box>;
};