import styled from 'styled-components';
import hexOpacity from 'hex-opacity';
import FONTS from 'constants/fonts';
const StyledInstructionItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  /* The last child is a scroll bottom view anchor */
  :nth-last-child(2) {
    margin-bottom: 0px;
  }
`;
interface BadgeProps {
  badgeColor: string;
}
const Badge = styled.span<BadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 16px;

  width: 24px;
  height: 24px;
  border-radius: 50%;
  flex-shrink: 0;
  font-size: ${FONTS.H5};

  background-color: ${props => hexOpacity.create(props.badgeColor, 0.2)};
`;
const TextBlock = styled.div`
  margin: 0;
  strong {
    font-weight: 500;
  }
`;
interface InstructionItemProps {
  index: number;
  badgeColor: string;
  children: React.ReactNode;
}
const InstructionItem = ({
  index,
  badgeColor,
  children
}: InstructionItemProps) => <StyledInstructionItem data-sentry-element="StyledInstructionItem" data-sentry-component="InstructionItem" data-sentry-source-file="InstructionItem.tsx">
    <Badge badgeColor={badgeColor} data-sentry-element="Badge" data-sentry-source-file="InstructionItem.tsx">{index}</Badge>
    <TextBlock data-sentry-element="TextBlock" data-sentry-source-file="InstructionItem.tsx">{children}</TextBlock>
  </StyledInstructionItem>;
export default InstructionItem;