import { trim } from 'lodash';
import { Param } from '../constants/params';

const paramsOnLoad = new URLSearchParams(window.location.search);

export const getUrlParamsOnLoad = () => {
  return paramsOnLoad;
};

export const query = <T extends string>(key: Param) => {
  const searchParams = new URLSearchParams(window.location.search);
  return trim(searchParams.get(key) ?? undefined) as T;
};
