import { useState } from 'react';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { Response as ValidateResponse } from '@finch-api/common/dist/internal/connect/validate';
import { useOAuthStatePolling } from '../../OAuth/useOAuthStatePolling';
import { replaceTemplateString } from '../../../../utils/replace-string';
import SignInBase from '../SignInBase';
import { getProviderFormLabel } from '../utils';

// Replace with an https endpoint (ngrok endpoint potentially) to test locally
const RIPPLING_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/auth/rippling`;
const SignInRipplingOAuth = ({
  client,
  provider,
  onSubmit,
  setError,
  sessionKey,
  onMockOAuth,
  idpRedirect
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const onCodeReceived = async (clientCode: string) => {
    await onSubmit({
      providerRedirectUri: RIPPLING_REDIRECT_URI,
      clientCode
    });
  };
  const {
    loading,
    openOAuthWindow
  } = useOAuthStatePolling({
    sessionKey,
    onCodeReceived,
    setError
  });
  const getOAuthUrl = (config: ValidateResponse['providersConfig'][number]['oauthCredentials']) => {
    if (!config || !config.authorizeUrl || !config.clientId) {
      throw new Error('Invalid OAuth config');
    }
    const url = new URL(replaceTemplateString(config.authorizeUrl, client.manualConfig));
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('client_id', config.clientId);
    url.searchParams.set('redirect_uri', RIPPLING_REDIRECT_URI);
    url.searchParams.set('state', JSON.stringify({
      sessionKey
    }));
    return url.toString();
  };
  const onOAuthClick = async () => {
    setButtonLoading(true);
    const didMockOAuth = await onMockOAuth();
    if (didMockOAuth) {
      setButtonLoading(false);
      return;
    }
    openOAuthWindow(getOAuthUrl(provider.oauthCredentials));
    setButtonLoading(false);
  };
  const onIdpRedirectClick = () => {
    return onSubmit({
      idpRedirect
    });
  };
  const instructions = <p>
      Rippling authentication will continue in a new window. Please make sure to
      allow popups and finish authenticating through Rippling in the new window.
    </p>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} data-sentry-element="SignInBase" data-sentry-component="SignInRipplingOAuth" data-sentry-source-file="SignInRipplingOAuth.tsx">
      <div style={{
      marginTop: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
        <LoaderButton isLoading={loading || buttonLoading} disabled={loading || buttonLoading} onClick={idpRedirect ? onIdpRedirectClick : onOAuthClick} data-sentry-element="LoaderButton" data-sentry-source-file="SignInRipplingOAuth.tsx">
          Connect
        </LoaderButton>
      </div>
    </SignInBase>;
};
export default withAuthorizeContext(SignInRipplingOAuth);