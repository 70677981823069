import { createElement, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Authorize from 'pages/Authorize';
import { AuthorizeProvider } from 'pages/Authorize/AuthorizeProvider';
import RedirectBase from 'pages/RedirectBase';
import { customOauthCallbackRoutes } from './pages/Authorize/OAuth/custom-oauth-callback-routes';
import { AnalyticsEventName, track } from 'utils/analytics';
import { useInitializeStagedConfig } from './pages/Authorize/useStagedConfig';
import { CompleteSession } from './pages/CompleteSession';
function App() {
  useEffect(() => {
    track(AnalyticsEventName.LaunchedFinchConnect);
  }, []);
  useInitializeStagedConfig();
  return <AuthorizeProvider data-sentry-element="AuthorizeProvider" data-sentry-component="App" data-sentry-source-file="App.jsx">
      <Switch data-sentry-element="Switch" data-sentry-source-file="App.jsx">
        <Route path="/authorize" render={props => <Authorize location={props.location} />} data-sentry-element="Route" data-sentry-source-file="App.jsx" />
        {customOauthCallbackRoutes.map(route => <Route path={route.path} key={route.path} render={() => createElement(route.component)} />)}
        <Route path="/complete-session" render={() => <CompleteSession />} data-sentry-element="Route" data-sentry-source-file="App.jsx" />
        <Route path="*" render={() => <RedirectBase />} data-sentry-element="Route" data-sentry-source-file="App.jsx" />
      </Switch>
    </AuthorizeProvider>;
}
export default App;