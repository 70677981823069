import _ from 'lodash';
import { productsConfig } from 'constants/products';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { isClientWithoutEmploymentIncome } from 'constants/clients';
import styled from 'styled-components';
import styles from './Permissions.module.scss';
import LoadingSpinner from '../LoadingSpinner';
import { GoBackButton } from 'components/Button/GoBackButton';
import { AvatarGroup, FinchAvatar, LetterAvatar } from 'components/Avatar';
import { useRegisterStep } from 'services/register-step';
import { AppShellButton } from 'components/Button/AppShellButton';
import { AppHeading } from 'components/Text/AppHeading';
import { Stack } from '@chakra-ui/react';
const Container = styled.div`
  position: relative;
  padding: 42px 24px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
`;
const productNameMap: Record<string, string> = {
  company: 'Company',
  directory: 'Directory',
  individual: 'Individual',
  employment: 'Employment',
  payment: 'Payment',
  pay_statement: 'Pay Statement',
  deduction: 'Deduction',
  ssn: 'SSN'
};
const PermissionList = ({
  client,
  nextStep,
  products,
  category,
  prevStep,
  idpRedirect
}: AuthorizeContextValue) => {
  const registerStep = useRegisterStep();
  if (idpRedirect) {
    nextStep();
    return <></>;
  }
  if (!client) return <Container>
        <LoadingSpinner />
      </Container>;
  if (!products) throw new Error('no products');
  const onContinue = () => {
    registerStep();
    nextStep();
  };
  const getPermissionText = (product: string) => {
    if (product === 'employment' && isClientWithoutEmploymentIncome(client.id)) {
      // remove income portion of text for these clients because we remove income data for them
      // TODO: make this a separate scope
      return productsConfig[category].employment_without_income.permissionText;
    }
    return productsConfig[category][product].permissionText;
  };
  return <div className={styles.container} data-sentry-component="PermissionList" data-sentry-source-file="PermissionList.tsx">
      <Stack data-sentry-element="Stack" data-sentry-source-file="PermissionList.tsx">
        <div className={styles.header}>
          <GoBackButton onClick={prevStep} data-sentry-element="GoBackButton" data-sentry-source-file="PermissionList.tsx" />
          <AvatarGroup data-sentry-element="AvatarGroup" data-sentry-source-file="PermissionList.tsx">
            <LetterAvatar letter={client.name[0]} data-sentry-element="LetterAvatar" data-sentry-source-file="PermissionList.tsx" />
            <FinchAvatar data-sentry-element="FinchAvatar" data-sentry-source-file="PermissionList.tsx" />
          </AvatarGroup>
        </div>
        <Stack gap="6" data-sentry-element="Stack" data-sentry-source-file="PermissionList.tsx">
          <AppHeading data-sentry-element="AppHeading" data-sentry-source-file="PermissionList.tsx">
            {client.name} requests access to the following permissions:
          </AppHeading>
          <Stack gap="4" maxH="460px" overflow="auto" data-sentry-element="Stack" data-sentry-source-file="PermissionList.tsx">
            {products.map(product => <div data-testid="permission" className={styles.permissionsBox} key={product}>
                <div className={styles.permissionsHeader}>
                  {productNameMap[product] ? productNameMap[product] : _.startCase(_.camelCase(product))}
                </div>
                <div className={styles.permissionsSubheader}>
                  {getPermissionText(product)}
                </div>
              </div>)}
          </Stack>
        </Stack>
      </Stack>
      <AppShellButton type="submit" onClick={onContinue} data-sentry-element="AppShellButton" data-sentry-source-file="PermissionList.tsx">
        Continue
      </AppShellButton>
    </div>;
};
export default withAuthorizeContext(PermissionList);