import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getBackendUrl } from './get-backend-url';

const BACKEND_ROUTE = getBackendUrl();

type GetInstructionsHtmlOpts = {
  instructionsLink: string | null;
};

export const getInstructionsHtml = async (opts: GetInstructionsHtmlOpts) => {
  const response = await axios.post(
    `${BACKEND_ROUTE}/auth/instructions-html`,
    opts,
  );

  return response.data;
};

const extractArticleId = (url: string): string | null => {
  const match = url.match(/\/articles\/(\d+)/);
  return match ? match[1] : null;
};

export const useInstructionsHtml = ({
  instructionsLink,
}: GetInstructionsHtmlOpts) => {
  const isFinchSupportLink = instructionsLink?.startsWith(
    'https://support.tryfinch.com/',
  );

  const query = useQuery({
    queryKey: ['instructions-html', instructionsLink],
    queryFn: async () => {
      if (!instructionsLink) return null;

      if (isFinchSupportLink) {
        const articleId = extractArticleId(instructionsLink);

        return axios
          .get<{ article: { body: string } }>(
            `https://finch6590.zendesk.com/api/v2/help_center/en-us/articles/${articleId}`,
          )
          .then((response) => response.data?.article.body);
      }

      const url = new URL(instructionsLink);
      const hostname = url.hostname;
      const response = await getInstructionsHtml({ instructionsLink });

      return response.html?.replace(
        /(src|href)="(?!http)/g,
        `$1="https://${hostname}`,
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!instructionsLink,
  });

  return { ...query, html: query.data, isFinchSupportLink };
};
