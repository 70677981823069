import { useMutation, useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';

export const useClipboard = (text: string) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 1000);
    }
  }, [copied]);

  const { mutate, error } = useMutation({
    mutationFn: () => navigator.clipboard.writeText(text),
  });

  return {
    error,
    copy: mutate,
    copied,
  };
};

export const useClipboardPermission = () => {
  return useQuery({
    queryKey: ['clipboard-permission'],
    queryFn: () =>
      navigator.permissions.query({
        name: 'clipboard-read' as PermissionName,
      }),
  });
};
