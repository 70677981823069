import styled from 'styled-components';
export const AvatarGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  div:first-child {
    margin-right: -17px;
    z-index: 10;
  }
  margin-bottom: 13px;
`;