import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import { credentialInstructions, getProviderFormLabel } from '../utils';
const SignInZenefitsCredential = ({
  isLoading,
  onSubmit,
  client,
  provider,
  handleClick
}: RegisterImplementationProps) => {
  const instructions = credentialInstructions(client.name);
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => {
    return <>
            <MainContainer useNewDesign>
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Email Address" id="username" />
              <PasswordInput reset={{
          uri: new URL('https://secure.zenefits.com/public/#/contact-us/login-issues'),
          providerDisplayName: provider.displayName
        }} value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} />
            </MainContainer>
            {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
                Use an API Token instead
              </SwitchImplementationButton>}
            <LoaderButton isLoading={isLoading} disabled={isLoading || !username.value || !password.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInZenefitsCredential" data-sentry-source-file="SignInZenefitsCredential.tsx" />;
};
export default SignInZenefitsCredential;