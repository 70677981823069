import { useLayoutEffect, useState } from 'react';

export const useIsVisible = (
  ref: React.RefObject<HTMLElement>,
  options: IntersectionObserverInit = {
    root: null,
    threshold: 0,
  },
) => {
  const [isVisible, setIsVisible] = useState<boolean>();

  useLayoutEffect(() => {
    const { current } = ref;

    if (!current) return;

    const observer = new IntersectionObserver(([entry]) => {
      const visible = entry.isIntersecting;
      setIsVisible(visible);
    }, options);

    observer.observe(current);

    return () => {
      observer.disconnect();
    };
  }, [ref, options]);

  return isVisible;
};
