import styled from 'styled-components';
import { lighten } from 'polished';

import COLORS from 'constants/colors';

const RedirectLink = styled.span`
  display: inline;
  background: none;
  border: none;
  margin-left: 3px;
  padding: 0;
  cursor: pointer;
  color: ${COLORS.RED.RED_600};
  text-decoration: underline;

  :hover {
    color: ${lighten(0.2, '#a80404')};
  }
`;

export default RedirectLink;
