export const Clients = {
  // This is us, Finch!
  Finch: {
    Dev: '00000000-0000-0000-0000-000000000000',
    Homepage: '5dc0e9dc-c411-4e4e-a749-0e35aac43080',
  },
  KarthikTestApp: {
    Prod: 'c90b78c6-2151-4ca3-8fea-ccb708ffc5d9', // testing org, "Karthik's Test Application"
  },
  // External Clients (plz keep a-z):
  Abacum: {
    Prod: '4d7f0ac9-1fd3-40c3-b96f-e2831988cfc0',
    Dev: '9683473e-ada5-41a5-a66a-e9e52f8fc3d9',
    Sandbox: 'b399c5be-b724-427b-bc39-35c6479dfb28',
  },
  AdditionWealth: {
    Prod: '6c766cc9-8b0f-4cc6-b105-cbf309e1bc90',
    Test: '32675c83-5350-4ed9-8632-feb8781cc21d',
    Sandbox: 'f639b60a-df14-4d19-a17a-c8c2dfa433de',
  },
  Aeqium: {
    Prod: '620846f7-c10b-4d5b-9814-b8f8842bdc9e',
    Dev: '2e074257-6b31-437d-96fe-e6680aac8237',
  },
  Aerodei: {
    Prod: '2f41d202-78f7-4180-bf6d-91a5e64e15aa',
  },
  AlliancePension: {
    Prod: 'a9b537d4-3217-4b7e-ba5f-53f1f1944654',
    Dev: '17e12014-2a14-4339-bd8c-350df505ec57',
  },
  Anrok: {
    Prod: 'c2141094-b5b2-487f-bc69-d98fbf747fbd',
    Dev: '51951844-0163-4989-b7cc-b2c64a9d49e0',
    Sandbox: 'af5104f9-f805-413c-99a2-98332f70e832',
  },
  Autire: {
    Prod: '21d43a64-d2d7-4d14-ab67-5a9f01a91dbd',
    Dev: '8f65b304-721e-4eb4-b888-598a8ea2a38d',
  },
  Axim: {
    Prod: '5a777ed6-cb42-4af5-bf0b-f397bf974620',
  },
  BalkanID: {
    Prod: 'e6e96204-782d-4aaa-8f1e-c228cc800998',
    Dev: 'f790bad6-639f-41ef-be72-1c3c10091d35',
    Sandbox: '00bf6fd4-620e-4bfa-9f0b-af6abb82b35d',
  },
  Bambee: {
    Prod: 'bc722685-1a25-4961-860e-26830d656fe6',
    Dev: 'd078f72e-8268-4299-be4a-70da94bb5091',
  },
  BasicCapital: {
    Prod: '232b9306-1aac-4ddf-ab55-e7907b708ce6',
  },
  BluebirdBenefits: {
    Prod: '14d7719d-d9f2-4fba-a08a-5ff5a8daad26',
    Dev: '694ddc9c-6a26-4a5e-87bf-2efbb955ca0e',
    Sandbox: '3e492248-22bb-41c7-a3e8-293579610670',
  },
  Carta: {
    Prod: '9db68cad-8137-4aff-8a2f-a4c27b692753',
    Demo: '9dc21f99-de4d-4bff-a95b-e69b655a1c92',
    Sandbox: 'e76c0e87-01dc-4403-af42-08e4490d74cd',
    Test: '8ee6af01-7000-4f2e-89b2-4a943665eb0a',
    Dev: '651d25c7-574e-4831-8d2f-aa61c50cf5d3',
  },
  Cascade: {
    Prod: 'e2f02c61-6e02-439f-8247-2222db0e64bf',
    Dev: '3727a318-b353-4ed5-acc8-57ab3c99db31',
    Sandbox: 'c7c1dbd7-35df-43a3-ac19-604166eea1df',
  },
  Causal: {
    Prod: 'b3b45875-21c9-4fa7-9da8-f7df4360e050',
    Dev: '9268f53b-d988-4e0c-891a-53b212f06aaf',
  },
  Check: {
    Dev: '22773a6c-8723-4854-8a53-61363a1b948f',
  },
  Cobbler: {
    Prod: '63fd71bf-d395-4cdc-bc5e-c6846f754735',
    Dev: 'c65bdf87-5322-4b78-a611-7a861157aa30',
  },
  Comprehensive: {
    Prod: 'd34c9210-4153-4c15-b4a2-a068872076c1',
    Dev: '1297fea8-01b6-46f2-b56f-a81b214484fb',
  },
  CoromandelCapital: {
    Prod: '914c9722-4ee8-4db3-bd97-dee3f0410ce5',
    Dev: '39ca6459-140b-4ac7-9279-21df49f35814',
    Sandbox: 'aacd0047-4858-46f0-ad48-982d1df8a6a1',
  },
  CorporateMerch: {
    Prod: 'af0b81b9-6870-4b82-aeba-dfb7d9150eb2',
    Dev: 'd37de7fa-7042-405e-a2c0-e74ef57da7ea',
  },
  Dashworks: {
    Prod: 'b9981883-f366-4534-8c69-9f6ffe79bc38',
    Dev: '0c7cb22d-bf59-4942-acb5-d8a0523922f1',
    Sandbox: '66f95466-dc85-4da7-bcf2-a9fb2c68ba74',
  },
  Decisely: {
    Prod: 'ab5c0ff2-0dd0-4d17-afdb-5218fe3b94e2',
    Dev: '49cfb040-65a0-48aa-b8cd-0cb113011c29',
    Sandbox: 'fa0e610f-b80a-4152-9d2c-10688e6e89f1',
  },
  ERCSpecialists: {
    Prod: 'e19d57b8-7e8d-4d60-a718-10803c21e739',
    Dev: 'd7e34ff5-af85-4a6a-aa80-fc58c6717cc7',
  },
  EZWorks: {
    Prod: '58e1814a-fffc-4c5a-aca5-1505bc643759',
    Dev: 'd38a49dd-6e95-4b0a-bc17-30dc80d409ea',
  },
  FirstDollar: {
    Prod: 'bd8c7ffd-7b04-441c-a6e3-aae1ff3a6b65',
    Dev: '649c4cc9-fc1b-4c29-96dd-05f08c3b4fd4',
  },
  ForUsAll: {
    Prod: '437629aa-54e2-4149-afb4-8b719f8dc759',
    Dev: '17804019-0d06-4b34-ac10-e34093922fc1',
  },
  Fundwurx: {
    Prod: '88c72ba3-2162-4e9a-8935-ee2741f20039',
  },
  Gather: {
    Prod: '249900e4-8b76-4415-9691-b4e284d9495b',
    Dev: '406042a1-937f-4105-bbb4-443bf1a00879',
    Staging: '5dce94a4-bc6b-402a-b18f-845882787e6b',
  },
  GreenPlaces: {
    Prod: 'bde9eb60-b0bd-4d13-9907-d4b21812ebe4',
    Dev: '2a3749e1-e569-41cc-ba2a-658a38b48f44',
    Sandbox: 'cb96ef8a-c4fb-4244-8ab0-bd8b15994c63',
  },
  Homebase: {
    Prod: '2aca6fe3-ed90-4ebf-a396-0369938489b1',
    Dev: '3b62860e-9461-4fbf-a5b1-30934c68bd74',
  },
  HumanInterest: {
    Prod: '09b88fcf-9ad8-4ccc-bfbd-0b0cc036d5cf',
    Dev: '71b1fb05-ae18-4c32-84d9-1625fbdff8fa',
  },
  Hyperproof: {
    Prod: '68dbdfbd-8df6-4785-99a1-aa3ad676dae1',
    Dev: '23432cf2-564a-4fc8-91fa-982450ae87b7',
  },
  IconSavingsPlan: {
    Prod: 'e7659474-3c52-4eff-a361-035d2e0b58db',
    Dev: 'ae3eee84-5dc1-4305-96f5-4b3af31497b8',
  },
  Immediate: {
    Prod: '1b1e0912-f0c8-4c5c-8dfa-2ab69828f26a',
    Dev: '382ab30c-0d1f-43e4-8cf2-39ac67152654',
    Sandbox: '54856051-ef09-4db7-8359-685eda07dc47',
  },
  InspireSoftware: {
    Prod: 'a1e2e2d9-5361-415f-92a7-1d8a624d35ec',
    Dev: '47e553a6-b7c6-4737-9630-23921a97b3b7',
    Sandbox: '9a2fb18f-134d-4236-a1c9-4d02f4872193',
  },
  Laika: {
    Prod: '00fd0f7c-9042-405f-ba80-a1486d9819e9',
    Dev: 'dc91e1b7-8db0-4eb1-a455-636f2e5f2b87',
  },
  Lane: {
    Prod: '19ae295f-193d-4042-83db-566a8c3de824',
    Dev: 'bf99a150-0f88-4572-8f24-af9773832da9',
  },
  Leeway: {
    Prod: '8a3b05f8-306a-4a11-9a50-822c10cd0d9a',
    Dev: 'c8558c1d-c5db-4a22-8081-feb9ab20b37a',
    Sandbox: '432730bf-1341-4882-b8bc-0495d54f0600',
  },
  Lendio: {
    Prod: '433072bf-9319-4809-9e5d-62756f9c50f1',
    Dev: 'ac947c02-9f6d-4b7f-ad15-5c5ece266636',
    Sandbox: '0721e11d-4529-4de9-929c-dfe4a4e79977',
  },
  Leyton: {
    Prod: 'c38c7b4b-2992-4b99-8360-ef749199161f',
    Dev: '397ebc7e-b860-4ab0-b363-18ec4bc038db',
  },
  Mainstreet: {
    Prod: 'ea5f8eb2-08e8-43e6-a76a-358cbe6cc180',
    Dev: '6c615114-c01b-44c7-9de9-3509dee4515c',
  },
  Middesk: {
    Prod: '58846bc9-0fb0-41a8-a60e-0b067ff20540',
    Dev: '07ebc73b-801e-4d99-a112-2c5342ab5d65',
  },
  Milliman: {
    Prod: 'cb1fbf3b-f1d1-4a65-b43c-a1f94823e547',
    Dev: '182dad96-3e15-43c2-9ed8-8dc250178fbf',
  },
  MJA: {
    Prod: '7547175b-1408-42e7-8801-320fa68560f6',
    Dev: '79b5f409-df98-405e-8d22-0e04eaeb27fa',
    Sandbox: '8b59f61d-4415-415f-b77b-5d0d4dac2c55',
  },
  Morrow: {
    Prod: 'ab2f6324-92b8-4866-9a44-314e23a6a83a',
    Dev: '009c643a-6f62-42cc-a317-a7444c5f7be9',
    Sandbox: 'a2d2ebe2-32b2-4a66-84a6-9bc079284358',
  },
  MosaicFinance: {
    Prod: 'a21b8d4d-72a5-447f-a5f2-2c4c7eb896bf',
    ParallelProduction: 'a1719773-1474-41a2-a588-aac0c50b71ad',
    Dev: '26e79c8a-690b-4093-82b1-a5a0790c4fca',
  },
  Mosey: {
    Prod: '41e5a8cb-0b9f-4a4e-9dd3-2070a2875238',
    Dev: 'd089d37e-57f8-4433-b757-2eedf8e6b7e2',
  },
  Mystery: {
    Dev: 'eeb3fad0-cb93-41dd-a854-d5f4c894a87b',
    Sandbox: '4e13fce0-21e5-444f-8a35-6a11da0a146c',
  },
  Nayya: {
    Prod: 'be0852ae-7aeb-4a53-ba6e-d17cba5559cf',
    Dev: 'f267bbc5-955d-475b-b035-15627ee3cde6',
  },
  Nectar: {
    Prod: '0a4186fb-ce5f-4b78-9831-5d4f7ede4257',
    Dev: 'c30609f1-4ec0-4207-97b5-6bba11b3c6d6',
  },
  NeoTax: {
    Prod: '5d252575-a182-432c-bd56-87b8b805fefd',
    Dev: 'eedcb43e-c5d6-4766-8850-a76f689adcb7',
  },
  NestEggs: {
    Prod: 'c69c9602-213b-47b4-be7b-eae90a1cb3df',
  },
  OpenComp: {
    Prod: 'f9ea0092-fca2-4a98-b60f-cecae170ab35',
    Dev: '09490b15-d769-4e27-a8ad-f0c1402c71eb',
  },
  Palolo: {
    Prod: '8e8a4249-9bde-4f0e-a6da-7e6b441fb530',
    Dev: '31ea3077-7883-4695-b4bb-899d9dbd3fa8',
  },
  Pave: {
    Prod: '9a286d3b-0eb4-4814-9ff7-f01166002726',
    Dev: '636d26f1-472c-403e-ba87-b6db50ef48a5',
  },
  PcsRetirement: {
    Prod: '78b5f152-0adc-428c-b8b1-52bd04f904a8',
  },
  PerkUp: {
    Prod: 'e140b4c1-18ce-4aaa-bd70-243528681b15',
  },
  Pry: {
    Prod: '4ef85cef-6511-4b7f-bfb1-60abfcfd9344',
  },
  Pulley: {
    Prod: 'd705ae56-e537-4d02-beb6-55f90652ca33',
    Dev: '5778ee23-2fba-4e63-9a07-8d8b3e0025b3',
    Sandbox: '40d811cd-a4e9-42ce-b861-3c2ab1bb5a33',
  },
  RPC: {
    Prod: '39a5fdd9-8b34-466d-ab7d-cdd84f608715',
  },
  Saturna: {
    Prod: 'd58cf1ce-cb62-45a6-9e58-67ea0a06d7c8',
    Dev: '3130063e-04a4-4e2d-a99d-ee51638cb16c',
    Sandbox: '24a40eda-3abe-4890-93cf-8c82de1768bd',
  },
  SaveDay: {
    Prod: '66d29788-7acc-47fa-b103-7dafbf5939d1',
  },
  Secureframe: {
    Prod: '430e385d-ad60-4fb2-9e8b-a429832591bc',
    Dev: 'a9e4d970-b283-4778-83be-b80cd002a486',
  },
  Sequoia: {
    Prod: 'c218abae-380b-486c-a30d-3506293aee4b',
    Dev: '01a0e474-ac91-481b-bf4e-36dc602aabe6',
  },
  Slavic401k: {
    Prod: 'd696211a-9feb-4024-9f91-848065d00ea2',
  },
  SmartPaySolutions: {
    // Note: SmartPay has another dev key '452ff16b-4718-4b3c-a9f0-695904ed2760' that is not being used
    Dev: '5900ffbc-4976-4fed-a7e0-78d488a1c160',
    Sandbox: 'ec533179-a89a-41b6-bc30-a657d81b33ae',
  },
  SureCo: {
    Prod: '68e1a34a-91c3-4631-9cbf-ba26f1458467',
    Dev: '29e749f0-0234-4b18-9960-33d677da36af',
    Sandbox: '71ec5b1b-22fd-42ab-97ab-e3e5357a0172',
  },
  Synechron: {
    // Synechron is the application name used by Transamerica
    Dev: '2823ab9b-8ecc-42c5-b944-2b0b2c556116',
  },
  Tangelo: {
    Prod: 'cb130cb8-e5e7-4dfc-8020-ea0f2788e6f9',
    Dev: 'd196d75f-07b7-4fe2-816b-cdfc16dc76e4',
  },
  TavaHealth: {
    Prod: '65fb709c-be20-4cf0-8e98-f7bd7ad0df47',
  },
  TempoPay: {
    Prod: '6aad0406-2f66-4593-8a61-c19a5b49b7af',
    Dev: '6fcf475f-6281-4b3c-8a4a-2e601b2565d4',
    Sandbox: '3a2f1f5b-ec4f-4b60-8fca-cdbc9d77861c',
  },
  ThinkLLC: {
    Prod: 'af5f549e-0027-4d1f-85d0-56bd9f068f57',
    Dev: '9e72e930-d062-4eba-b626-931755685125',
    Sandbox: 'ecef759f-7a2b-4416-8ef5-c80b82686416',
  },
  Trainual: {
    Prod: '7d0e58e7-0ad0-4c07-9caa-0866818d7687',
    Dev: 'd9f6f5c5-4a7c-4d6b-8f1d-6c5a2d3b8b8a',
  },
  Transamerica: {
    Sandbox: 'b11fe6c6-6075-403a-a6d3-aa3984a6d657',
    Dev: 'de9ba8a0-b19a-411c-ac39-51e1cf4434cc',
  },
  Trueplan: {
    Prod: '4c1405ca-734f-4a54-985f-919fd4260983',
    Dev: '43853ee5-aeb8-40fc-9d68-609e73fb2c27',
  },
  TWGBenefits: {
    Prod: '7d156e64-7f59-4e65-a407-d099a8e6aee6',
  },
  Ubiquity: {
    Prod: '9ea9ccbb-9626-4d82-873a-3f41de225ee8',
  },
  Udext: {
    Prod: 'f3ca5cb4-555b-4763-979e-5293c4387279',
  },
  Vanta: {
    Prod: 'fd4c1d51-7946-4b40-8c36-ee8bdaec6527',
    Dev: 'a5681048-8198-4c66-86d8-8f087e443570',
  },
  Venteur: {
    Prod: 'fc840785-3da8-4576-9eb9-cb4c6e0da089',
    Dev: 'f21f0a69-be61-4121-ac25-cd1e02cef8f3',
    Sandbox: '0e4034aa-d12a-4c7a-a856-69fb7ccbd726',
  },
  Vestwell: {
    Prod: 'd2c67a75-0ee2-4f62-a27a-ccdc06594e80',
  },
  VisualWorkforce: {
    Prod: '901f1e27-2ffa-4c0c-a9af-738c26ec06b5',
    Dev: '31ab4dcf-a03c-4f05-bb43-9dca72443d68',
    Sandbox: '982dba15-ff13-4115-b2b3-35242dfff276',
  },
  VitableHealth: {
    Prod: '5e31d336-ca12-430a-a67d-2043921affa7',
    Dev: 'cfb21a5f-4140-4645-9ff2-05d713a4924f',
    Sandbox: '99172f69-ee45-4a8f-92ff-53f9f028b843',
  },
  Welcome: {
    Prod: '838c12f6-1dff-4757-b23b-bd64871d5411',
  },
  WorkProud: {
    Prod: 'c63af859-5c73-46fb-a3ee-beefcbf6d77e',
    Dev: '256940f7-c19b-402b-aa73-2e0059bcb1c4',
    Sandbox: 'c6c324d6-766a-4ba6-b8d0-94321fe41016',
  },
  Worksphere: {
    Prod: 'a2ef6b5d-22d5-4dd8-91e9-8356938263bb',
    Dev: 'ef9c228f-e1ad-44ff-bafa-8e1f478d783f',
  },
  Yourco: {
    Prod: 'cf9d4371-8497-410a-818d-68fa9d298bcf',
    Dev: '8e0c79fc-cdfc-41ad-ad2e-b2f1d2dc3d12',
  },
  Zazos: {
    Prod: 'b11813df-cec4-4120-8792-962885080806',
  },
  AuditMiner: {
    Prod: '23292bb1-b8f5-4991-b619-9ceac55259f5',
    Dev: '1e0e4b52-a4e3-4a05-a1ea-dde01dd85256',
  },
  InsurePay: {
    Prod: 'f3816314-10ee-46c1-893c-77c391fc9b5b',
    Dev: 'e76a4131-30bd-4976-a4a4-7137f5eb57cf',
    Sandbox: '13219ca9-ace0-429a-98ad-5b3c1b4fbabe',
  },
  Thatch: {
    Prod: 'a6fb754b-0fb1-415e-a890-9991b566141a',
    Dev: '83cbf0cd-e8c5-43bd-bab9-fc30743feb91',
    Sandbox: 'ac9c71b3-6cc8-49a9-bca3-b07d79446faf',
  },
  Nova401k: {
    Prod: '18260f0f-dff8-4894-b200-46b9a0d098f0',
  },
};

const clientsWithoutEmploymentIncome = new Set([
  ...Object.values(Clients.Anrok),
  ...Object.values(Clients.BalkanID),
  ...Object.values(Clients.CorporateMerch),
  ...Object.values(Clients.Dashworks),
  ...Object.values(Clients.Gather),
  ...Object.values(Clients.GreenPlaces),
  ...Object.values(Clients.Laika),
  ...Object.values(Clients.Mystery),
  ...Object.values(Clients.Nectar),
  ...Object.values(Clients.Secureframe),
  ...Object.values(Clients.PerkUp),
  ...Object.values(Clients.Tangelo),
  ...Object.values(Clients.TavaHealth),
  ...Object.values(Clients.Vanta),
  ...Object.values(Clients.Worksphere),
  ...Object.values(Clients.Yourco),
]);

// TODO: make this a separate scope
export const isClientWithoutEmploymentIncome = (clientId: string) =>
  clientsWithoutEmploymentIncome.has(clientId);
