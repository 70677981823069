import * as Sentry from '@sentry/react';

import {
  FinchConnectAuthMessage,
  MessageKind,
  POST_MESSAGE_NAME,
} from '@finch-api/common/dist/internal/connect/sdk';

import {
  paramCode,
  paramIdpRedirectUri,
  paramState,
} from '../constants/params';
import { query } from './params';
import { NO_REFERRER_URL } from '../constants/sdk';
import { ConnectError } from 'pages/Authorize/types';

export type ErrorType = 'validation_error' | 'employer_connection_error';
export const POST_MESSAGE_NAME_V2 = `${POST_MESSAGE_NAME}-v2` as const;

export const getSpaRedirect = (opts?: { redirectUri: string }): string => {
  const { redirectUri } = opts || {};

  // The following ternary is if our flow ends up navigating to a page with a different
  // origin. This case should not actually be hit.
  const sdkHostUrl = query('sdk_host_url');
  const url =
    (sdkHostUrl && sdkHostUrl !== NO_REFERRER_URL
      ? sdkHostUrl
      : window.location !== window.parent.location
      ? document.referrer
      : document.location.origin) ||
    window.parent?.location?.origin ||
    window.opener?.location?.origin; // Use opener for Safari

  if (!url) {
    Sentry.setContext('AuthorizeContext', {
      sdkHostUrl,
      referrer: document.referrer,
      location: document.location,
      windowLocation: window.location,
      windowParentLocation: window.parent.location,
      windowOpenerLocation: window.opener?.location,
    });

    Sentry.captureException('No url found for redirect');
  }

  if (url !== redirectUri) {
    // TODO: how should we handle this?
  }
  return url;
};

export const getSpaMessage = (opts: {
  redirectTo?: string;
  error?: ConnectError;
  closed?: boolean;
}): FinchConnectAuthMessage => {
  const { redirectTo, error, closed } = opts;

  if (closed) {
    return {
      name: POST_MESSAGE_NAME,
      kind: MessageKind.CLOSED,
      closed: true,
    };
  }

  if (redirectTo) {
    const redirectToUri = new URL(redirectTo);
    const code = redirectToUri.searchParams.get(paramCode);
    const idpRedirectUri =
      redirectToUri.searchParams.get(paramIdpRedirectUri) ?? undefined;
    const state = redirectToUri.searchParams.get(paramState) ?? undefined;

    if (code) {
      return {
        name: POST_MESSAGE_NAME,
        kind: MessageKind.SUCCESS,
        code,
        idpRedirectUri,
        state,
      };
    }
  }

  return {
    name: POST_MESSAGE_NAME,
    kind: MessageKind.ERROR,
    error: {
      message: error?.message,
      /**
       * Default to employer_connection_error because most errors are of this type.
       */
      type: error?.type ?? 'employer_connection_error',
      shouldClose: error?.type === 'validation_error',
    },
  };
};

export const sendSdkMessage = (opts: {
  message: FinchConnectAuthMessage;
  url: string;
  /**
   * The version of the SDK that should receive the message.
   */
  namespaceVersions?: ('v1' | 'v2')[];
}) => {
  const { message, url, namespaceVersions = ['v1', 'v2'] } = opts;

  namespaceVersions.forEach((version) => {
    if (version === 'v1') {
      const error =
        'error' in message
          ? (message.error as ConnectError)?.message
          : undefined;

      window.parent.postMessage(
        { ...message, ...(error && { error }), name: POST_MESSAGE_NAME },
        url,
      );
    } else {
      window.parent.postMessage(
        { ...message, name: POST_MESSAGE_NAME_V2 },
        url,
      );
    }
  });
};
