import Container from 'components/Container/Container';
import Header from 'components/Header/Header';
import payrollProviderToLocalLogosMap from 'constants/payrollProviderToLocalLogosMap';
import ProviderLogo from 'components/Logo/ProviderLogo';
import { ImplementationDetail, ProviderConfig } from '@finch-api/common/dist/internal/connect/validate';
import { GoBackButton } from 'components/Button/GoBackButton';
import { ConnectError } from '../types';
import { Badge, Box, Center, HStack, Stack, StackSeparator } from '@chakra-ui/react';
import { useIsVisible } from 'hooks/use-is-visible';
import { useRef } from 'react';
import { DownArrowIcon } from 'components/Icons/DownArrowIcon';
import { AUTOMATED_AUTH_INSTRUCTIONS } from 'constants/automated-auth-instructions';
import { PayrollProviderId } from 'constants/types';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { usePopOutInstructionsLink } from 'store/pop-out-instructions';
import { InstructionsPopout } from './InstructionsPopout';
import { ProviderAppShellError } from './ProviderAppShellError';
import { SCROLL_SHADOW } from './constants';
import BetaTag from 'components/VersionTag/BetaTag';
import { isImplementationInBeta } from 'utils/providers';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlag } from 'constants/feature-flags';
import { useAuthorizeContext } from '../AuthorizeContext';
import { useNewProviderComponent } from '../SignIn/providerToComponentMap';
import { AuthorizeLoadingPage } from '../LoadingPage/AuthorizeLoadingPage';
type AppShellProps = {
  children: React.ReactNode;
  provider: ProviderConfig;
  providerImplementation: ImplementationDetail;
  goBack?: () => void;
  headerText?: string;
  error: ConnectError | null;
  setError: (error: ConnectError | null) => void;
};
export const ProviderAppShell = ({
  children,
  provider,
  goBack,
  headerText,
  error,
  setError,
  providerImplementation
}: AppShellProps) => {
  const pageBottom = useRef<HTMLDivElement>(null);
  const root = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(pageBottom, {
    root: root.current,
    // This is to ensure that the 'More fields' badge disappears when the button is visible on the page
    rootMargin: '30px'
  });
  const showInstructions = usePopOutInstructionsLink(state => state.popOutInstructionsLink);
  const popOutInstructionsEnabled = useFlag(FeatureFlag.PopOutInstructionsEnabled);
  const {
    client,
    authorizeLoading
  } = useAuthorizeContext() || {};
  const useNewDesign = useFlag('finchConnectCleanup');
  const newContainerDesign = useNewProviderComponent(provider.id as PayrollProviderId, providerImplementation.kind as ImplementationKind, useNewDesign);
  const instructionsLink = AUTOMATED_AUTH_INSTRUCTIONS[provider.id as PayrollProviderId]?.[providerImplementation.kind as ImplementationKind];
  return <HStack gap="0" separator={<StackSeparator />} h="full" data-sentry-element="HStack" data-sentry-component="ProviderAppShell" data-sentry-source-file="ProviderAppShell.tsx">
      <Stack w="400px" h="full" data-sentry-element="Stack" data-sentry-source-file="ProviderAppShell.tsx">
        <Container newContainerDesign={newContainerDesign} data-sentry-element="Container" data-sentry-source-file="ProviderAppShell.tsx">
          {isImplementationInBeta(providerImplementation) && <BetaTag />}
          <Center data-sentry-element="Center" data-sentry-source-file="ProviderAppShell.tsx">
            <ProviderLogo src={payrollProviderToLocalLogosMap[provider.id].largeLogo} alt="" data-sentry-element="ProviderLogo" data-sentry-source-file="ProviderAppShell.tsx" />
          </Center>

          {goBack && <GoBackButton onClick={goBack} />}

          <Stack overflow="auto" h="100%" w="100%" gap="0" scrollbar="hidden" {...SCROLL_SHADOW} ref={root} data-sentry-element="Stack" data-sentry-source-file="ProviderAppShell.tsx">
            <Stack flexGrow={1} gap="4" data-sentry-element="Stack" data-sentry-source-file="ProviderAppShell.tsx">
              {authorizeLoading && newContainerDesign && <AuthorizeLoadingPage provider={provider.displayName} clientName={client?.name} />}

              {error && <ProviderAppShellError error={error} setError={setError} provider={provider} providerImplementation={providerImplementation} instructionsLink={instructionsLink} />}

              {headerText && <Header>{headerText}</Header>}
              {children}
            </Stack>

            <Box ref={pageBottom} data-sentry-element="Box" data-sentry-source-file="ProviderAppShell.tsx" />
          </Stack>
          {isVisible === false && <Badge variant="solid" colorScheme="black" pos="absolute" bottom="24px" fontSize="13px" rounded="full" px="10px" py="7.5px" gap="8px">
              <DownArrowIcon />
              More fields
            </Badge>}
        </Container>
      </Stack>
      {showInstructions && popOutInstructionsEnabled && <InstructionsPopout />}
    </HStack>;
};