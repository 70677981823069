import { ProviderConfig } from '@finch-api/common/dist/internal/connect/validate';
import { useEffect } from 'react';
import { create } from 'zustand';
const messageOrigin = process.env.NODE_ENV === 'production' ? 'https://dashboard.tryfinch.com' : 'http://localhost:3001';
export const useStagedConfigStore = create<{
  stagedConfig: ProviderConfig[] | null;
  setStagedConfig: (stagedConfig: ProviderConfig[] | null) => void;
}>(set => ({
  stagedConfig: null,
  setStagedConfig: (stagedConfig: ProviderConfig[] | null) => set(() => ({
    stagedConfig
  }))
}));
export const useInitializeStagedConfig = () => {
  const setStagedConfig = useStagedConfigStore(state => state.setStagedConfig);
  useEffect(() => {
    function handleStagedConfig(event: MessageEvent<{
      type: 'stagedConfig';
      content: ProviderConfig[];
    }>) {
      if (event.origin !== messageOrigin) return;
      if (event.data.type !== 'stagedConfig') return;
      setStagedConfig(event.data.content);
    }
    window.addEventListener('message', handleStagedConfig);
    return () => {
      window.removeEventListener('message', handleStagedConfig);
    };
  }, [setStagedConfig]);
};