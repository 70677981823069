import styled from 'styled-components';
import { generateEmailWithRandomNumberAlias } from './utils';
type InstructionValues = {
  username: string;
  accountantEmail?: string;
  userId: string;
  ssn?: string;
  phoneNumber: string;
};
type InstructionDetails = {
  label: string;
  value?: string | null;
};
type InstructionsFn = (args: InstructionValues) => InstructionDetails[];
type TroubleshootingFn = (args: InstructionValues) => JSX.Element;
const generic: InstructionsFn = ({
  username,
  accountantEmail
}) => [{
  label: 'Username',
  value: username
}, {
  label: 'Email',
  value: accountantEmail
}];
export const instructions: {
  [payrollProviderId: string]: InstructionsFn;
} = {
  aap_isolved: generic,
  abacus_isolved: generic,
  absolute_isolved: generic,
  accountantsworld: generic,
  accupay_isolved: generic,
  ace_isolved: generic,
  adams_keegan: generic,
  adp_comprehensive: ({
    accountantEmail
  }) => [{
    label: 'Email',
    value: accountantEmail
  }],
  adp_enterprise: generic,
  adp_run: ({
    userId,
    username,
    accountantEmail
  }) => [{
    label: 'User ID',
    value: userId
  }, {
    label: 'First Name',
    value: username
  }, {
    label: 'Last Name',
    value: 'Support'
  }, {
    label: 'Email',
    value: accountantEmail
  }, {
    label: 'Role',
    value: 'Owner'
  }],
  adp_teampay: generic,
  adp_totalsource: ({
    accountantEmail
  }) => [{
    label: 'Email',
    value: accountantEmail
  }],
  adp_vantage: generic,
  adp_workforce_now: ({
    userId,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: userId
  }, {
    label: 'Last Name',
    value: 'Support'
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  adpnextgen: generic,
  advantage_payroll_services: ({
    accountantEmail
  }) => [{
    label: 'Email Address',
    value: accountantEmail
  }],
  affiliated_hr_payroll_services_evolution: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Username',
    value: accountantEmail
  }],
  affiliated_hr_payroll_services_isolved: generic,
  ahola: generic,
  alcott_hr: generic,
  alight: generic,
  alliance: generic,
  alphastaff: generic,
  amplify_hr: generic,
  apdata: generic,
  apex: generic,
  aps_payroll: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'User Name',
    value: username
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  armhr: generic,
  aspenhr: generic,
  asset_hr: generic,
  asure_software: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Username',
    value: accountantEmail
  }],
  balance_point: generic,
  bambee: generic,
  bamboo_hr: () => [],
  baron_isolved: generic,
  basic: generic,
  bayzat: generic,
  bbcs: generic,
  bbsi: generic,
  bcn_services: generic,
  benecare: generic,
  big_fish_employer_services: generic,
  bizchecks_payroll: generic,
  bob: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  business_online_payroll: generic,
  businesssolver: generic,
  c2: generic,
  ceridian_dayforce: ({
    username,
    accountantEmail
  }) => [{
    label: 'Name',
    value: username
  }, {
    label: 'User Name/Email',
    value: accountantEmail
  }],
  ceridian_powerpay: generic,
  cezannehr: generic,
  charlie_hr: ({
    userId,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: userId
  }, {
    label: 'Last Name',
    value: 'Support'
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  checkwriters: generic,
  clickup: generic,
  cmic: generic,
  coadvantage: generic,
  coastal_payroll: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  cognos_hr: generic,
  collage: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  commonwealth_payroll_hr: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  connectpay: generic,
  corporatepayroll: generic,
  costaff: generic,
  cpm_isolved: generic,
  creative_business_resources: generic,
  crescent_payroll_solutions: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  criterion: generic,
  csc_paymaster: generic,
  darwinbox: generic,
  decent: generic,
  deel: ({
    userId,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: userId
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  deltek: generic,
  deluxe: generic,
  dm_payroll_solutions: generic,
  dominion_payroll: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  doyle_hcm: generic,
  eddyhr: generic,
  elite_payroll: generic,
  emcentrix: generic,
  emplicity: generic,
  employdrive: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  employer_flexible: generic,
  employmenthero: generic,
  empower_hr: generic,
  engage_peo: generic,
  esc: generic,
  everee: generic,
  evolution_payroll_services: generic,
  excel_resources: generic,
  extensis_hr: generic,
  flock: generic,
  freshteam: generic,
  freshworks: generic,
  fullstack_peo: generic,
  gigwage: generic,
  gna_partners: generic,
  goco: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  greyt_hr: generic,
  gtm_payroll_services_evolution: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Username',
    value: accountantEmail
  }],
  gtm_payroll_services_isolved: generic,
  gusto: ({
    userId,
    accountantEmail
  }) => [{
    label: 'Name',
    value: userId
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  heartland: generic,
  helpside: generic,
  highflyer_hr: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  homebase: generic,
  horizonpayroll: generic,
  horizons: generic,
  hrcloud: generic,
  hro: generic,
  hrone: generic,
  hubstaff: ({
    accountantEmail
  }) => [{
    label: 'Email',
    value: accountantEmail
  }],
  humaans: generic,
  humi: generic,
  hybrid: generic,
  infinipay: generic,
  infiniti_hr: generic,
  infor: generic,
  insperity: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  iris_hr: generic,
  isolved: generic,
  justworks: ({
    userId,
    accountantEmail
  }) => [{
    label: 'First and last name',
    value: userId
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  keka: generic,
  kenjo: generic,
  keystone_isolved: generic,
  miter: generic,
  mp: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  mpay: generic,
  multiplier: generic,
  namely: ({
    accountantEmail
  }) => [{
    label: 'Email Address',
    value: accountantEmail
  }],
  natural_hr: generic,
  netchex: generic,
  newtek: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  nextep: generic,
  north_bay_payroll: generic,
  nvision: generic,
  oasis: generic,
  obsidianhr_isolved: generic,
  odoo: generic,
  omni_hr: generic,
  onepointhcm: generic,
  onesource_payroll: generic,
  onpay: ({
    userId,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: userId
  }, {
    label: 'Last Name',
    value: 'Support'
  }, {
    label: 'Work Email',
    value: accountantEmail
  }],
  opolis: generic,
  oracle_fusion: generic,
  oracle_peoplesoft: generic,
  panther: generic,
  paragon_payroll_isolved: generic,
  patriot: ({
    accountantEmail
  }) => [{
    label: 'Email',
    value: accountantEmail
  }],
  pay_usa_inc: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  paychex_flex: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  paycom: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  paycor: ({
    userId,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: userId
  }, {
    label: 'Last Name',
    value: 'Support'
  }, {
    label: 'Work Email',
    value: accountantEmail
  }, {
    label: 'Work Number',
    value: '917-717-3974'
  }, {
    label: 'Contact Type',
    value: 'Administrator or Primary'
  }],
  payday_workforce_solutions: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  payentry: generic,
  payfit: ({
    userId,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: userId
  }, {
    label: 'Last Name',
    value: 'Support'
  }, {
    label: 'Email Address',
    value: accountantEmail
  }, {
    label: 'Role',
    value: 'Administrator'
  }],
  payfluence: generic,
  paylocity: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  paynw: generic,
  paypro_hcs_isolved: generic,
  payroll_for_construction: generic,
  payroll_network: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  payroll_office_of_america: generic,
  payroll_plus_hcm_evolution: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Username',
    value: accountantEmail
  }],
  payroll_plus_hcm_isolved: generic,
  payroll_resource_group: generic,
  payroll_solutions: generic,
  payrollontime: generic,
  paytime: generic,
  pcs_hcm: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  penta: generic,
  people_lease: generic,
  peoplecore: generic,
  peopleforce: generic,
  peoplehum: generic,
  personio: generic,
  platinum_group: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  premiernow: generic,
  prestige_peo: generic,
  primepay: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  prism_hr: generic,
  proliant: generic,
  propel_hr: generic,
  prosoftware: generic,
  proxus_hr: generic,
  quality_payroll_benefits: generic,
  questco: generic,
  quickbooks: ({
    userId,
    accountantEmail
  }) => [{
    label: 'First and last name',
    value: userId
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  quickhr: generic,
  remote: generic,
  remotepass: generic,
  resourcing_edge: generic,
  restaurant365: generic,
  rippling: ({
    username,
    accountantEmail
  }) => [{
    label: 'Name',
    value: username
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  rmi_peo: generic,
  runforpartners: ({
    userId,
    accountantEmail
  }) => [{
    label: 'User ID',
    value: userId
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  sage_hr: () => [],
  sage_payroll: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  sapling: ({
    userId,
    accountantEmail,
    username
  }) => [{
    label: 'Username',
    value: userId
  }, {
    label: 'Email',
    value: accountantEmail
  }, {
    label: 'Administrator',
    value: username
  }],
  savant_hcm_evolution: ({
    accountantEmail,
    username
  }) => [{
    label: 'Username',
    value: accountantEmail
  }, {
    label: 'First Name',
    value: username
  }],
  sequoia_one: ({
    username,
    accountantEmail
  }) => [{
    label: 'Name',
    value: username
  }, {
    label: 'Email',
    value: generateEmailWithRandomNumberAlias(accountantEmail)
  }],
  sesame: generic,
  sheakley: generic,
  simploy: generic,
  skuad: generic,
  solution_services: generic,
  sourceone: generic,
  southeastpersonnel: generic,
  square_payroll: ({
    username,
    accountantEmail,
    phoneNumber
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }, {
    label: 'Phone Number',
    value: phoneNumber
  }],
  strategic_payroll_solutions_isolved: generic,
  strongpay: generic,
  successfactors: ({
    userId
  }) => [{
    label: 'Group Name',
    value: userId
  }, {
    label: 'User ID',
    value: userId
  }],
  suite_people: generic,
  sunfish: generic,
  surepayroll: generic,
  sync_hr: ({
    username,
    accountantEmail,
    phoneNumber
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Email',
    value: accountantEmail
  }, {
    label: 'Phone Number',
    value: phoneNumber
  }],
  synchronyhr: generic,
  talenta: generic,
  tandemhr: generic,
  teamworks: generic,
  the_payroll_company: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  thread_hcm: generic,
  toast_payroll: ({
    userId,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: userId
  }, {
    label: 'Last Name',
    value: 'Support'
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  trakstar: generic,
  trinet: ({
    username,
    ssn,
    accountantEmail,
    phoneNumber
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'SSN',
    value: ssn
  }, {
    label: 'Email',
    value: accountantEmail
  }, {
    label: 'Phone',
    value: phoneNumber
  }],
  ukg_ready: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  ulti_pro: ({
    username,
    userId,
    accountantEmail
  }) => [{
    label: 'Name',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }, {
    label: 'User Name',
    value: userId
  }],
  uzio: generic,
  velocity_global: generic,
  vensure_hr: generic,
  venture_workforce: generic,
  vfficient: generic,
  viewpoint_hr_management_spectrum: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  viewpoint_hr_management_vista: ({
    username
  }) => [{
    label: 'Name',
    value: username
  }],
  viventium: generic,
  wagepoint: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  warp: generic,
  wave: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Email',
    value: accountantEmail
  }],
  web_hr: ({
    userId,
    accountantEmail
  }) => [{
    label: 'User Name',
    value: userId
  }, {
    label: 'First Name',
    value: userId
  }, {
    label: 'Last Name',
    value: 'Admin'
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  whirks: ({
    username,
    accountantEmail
  }) => [{
    label: 'Username',
    value: username
  }, {
    label: 'Email Address',
    value: accountantEmail
  }],
  wolke: generic,
  workday: ({
    userId,
    accountantEmail
  }) => [{
    label: 'User Name',
    value: userId
  }, {
    label: 'Email Address',
    value: accountantEmail
  }, {
    label: 'Security Group Name',
    value: userId
  }],
  workforce_junction: generic,
  worklio: generic,
  workplace_hcm: generic,
  workzoom: generic,
  wurk: ({
    username,
    accountantEmail
  }) => [{
    label: 'First Name',
    value: username
  }, {
    label: 'Username',
    value: username
  }, {
    label: 'Primary Email',
    value: accountantEmail
  }],
  xenium_hr: generic,
  xero: generic,
  xero_us: () => [],
  zenefits: () => [],
  zimyo: generic,
  zoho_payroll: ({
    userId,
    accountantEmail
  }) => [{
    label: 'Name',
    value: userId
  }, {
    label: 'Email Address',
    value: accountantEmail
  }]
};
export const troubleshooting: {
  [payrollProviderId: string]: TroubleshootingFn;
} = {
  advantage_payroll_services: ({
    username,
    accountantEmail
  }) => <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>,
  bamboo_hr: ({
    username,
    accountantEmail
  }) => <>
      CC <strong>{username}</strong> using the following email address:{' '}
      <strong>{accountantEmail}</strong>.
    </>,
  charlie_hr: ({
    username,
    accountantEmail
  }) => <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Admin. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>,
  onpay: ({
    username,
    accountantEmail
  }) => <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>,
  paycor: ({
    username,
    accountantEmail
  }) => <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>,
  payfit: ({
    username,
    accountantEmail
  }) => <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>,
  sage_hr: ({
    username,
    accountantEmail
  }) => <>
      CC <strong>{username}</strong> using the following email address:{' '}
      <strong>{accountantEmail}</strong>.
    </>,
  successfactors: ({
    username,
    accountantEmail
  }) => <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>,
  web_hr: ({
    username,
    accountantEmail
  }) => <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>,
  workday: ({
    username,
    accountantEmail
  }) => <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>,
  xero_us: () => <>
      Xero outsources all US payroll to Gusto. Please click{' '}
      <strong>Redirect</strong> to sign in with Gusto.
    </>,
  zenefits: ({
    username,
    accountantEmail
  }) => <>
      CC <strong>{username}</strong> using the following email address:{' '}
      <strong>{accountantEmail}</strong>.
    </>,
  zoho_payroll: ({
    username,
    accountantEmail
  }) => <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>
};
const Divider = styled.hr`
  width: 100%;
  background-color: #d9d9d9;
  height: 1px;
  border-width: 0;
  margin: 24px 0;
`;
export const detailsAndTroubleshooting = (payrollProviderId: string, values: InstructionValues) => {
  if (!(payrollProviderId in instructions)) throw new Error(`no instructions for provider ${payrollProviderId}`);
  const providerDetails = instructions[payrollProviderId](values).map(({
    label,
    value
  }) => {
    return <div key={label}>
          <strong>{label}</strong>: {value}
        </div>;
  });
  const providerTroubleshooting = troubleshooting[payrollProviderId] ? troubleshooting[payrollProviderId](values) : undefined;
  if (providerDetails.length === 0 && !providerTroubleshooting) return <></>;
  return <div style={{
    marginBottom: '8px'
  }} data-sentry-component="detailsAndTroubleshooting" data-sentry-source-file="providerInstructionDetails.tsx">
      <Divider data-sentry-element="Divider" data-sentry-source-file="providerInstructionDetails.tsx" />
      <strong>Information needed for completing instructions:</strong>
      <br />
      <br />
      {providerDetails}
      {providerDetails.length > 0 && !!providerTroubleshooting && <br />}
      {providerTroubleshooting}
    </div>;
};