import { Box, HStack, Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from 'components/Avatar/ExternalLinkIcon';
import { AppLink } from './AppLink';
export function ResetPasswordOnProviderLink({
  href,
  provider
}: {
  href?: string;
  provider: string;
}): JSX.Element | null {
  if (!href) {
    return null;
  }
  return <HStack justify="flex-end" data-sentry-element="HStack" data-sentry-component="ResetPasswordOnProviderLink" data-sentry-source-file="ResetPasswordOnProviderLink.tsx">
      <AppLink fontSize="sm" fontWeight="500" href={href} target="_blank" rel="noreferrer" minW="0" data-sentry-element="AppLink" data-sentry-source-file="ResetPasswordOnProviderLink.tsx">
        <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" data-sentry-element="Text" data-sentry-source-file="ResetPasswordOnProviderLink.tsx">
          Reset password on {provider}
        </Text>
      </AppLink>

      <ExternalLinkIcon data-sentry-element="ExternalLinkIcon" data-sentry-source-file="ResetPasswordOnProviderLink.tsx" />
    </HStack>;
}