import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import LoaderButton from 'components/Button/LoaderButton';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { RegisterImplementationProps } from 'constants/types';
import PasswordInput from 'components/Input/PasswordInput';
import MainContainer from 'components/Container/MainContainer';
import { getProviderFormLabel } from '../utils';
const RegisterPaychexFlexApi = ({
  client,
  provider,
  isLoading,
  onSubmit,
  handleClick
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      Log in and click on <strong>Menu</strong> and{' '}
      <strong>Company Settings</strong> in the upper-left corner of the page.
    </>, <>
      Click on <strong>Integrated Apps</strong>. Then click on{' '}
      <strong>Create App</strong>.
    </>, <>
      Enter an <strong>Application Name</strong> and{' '}
      <strong>Description</strong>. Under <strong>Access Settings</strong>,
      enable the <strong>Company & People</strong> option, and select{' '}
      <strong>View Only</strong>. Accept the terms and click{' '}
      <strong>Save</strong>.
    </>, <>
      Copy the key and click <strong>Done</strong>. Paste the API credentials
      into the box below.
    </>];
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterPaychexFlexApi.tsx">
        To connect your Paychex Flex account, you must create an API Token for{' '}
        <strong>{client.name}</strong>.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterPaychexFlexApi.tsx" />
      <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['providerClientId', 'providerClientSecret']} render={({
      providerClientId,
      providerClientSecret
    }) => <>
            <MainContainer>
              <Input id="providerClientId" value={providerClientId.value} onChange={event => providerClientId.onChange(event.target.value)} invalid={providerClientId.invalid} label="Client ID" />
              <PasswordInput id="providerClientSecret" value={providerClientSecret.value} onChange={event => providerClientSecret.onChange(event.target.value)} invalid={providerClientSecret.invalid} label="Client Secret" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterPaychexFlexApi.tsx" />
      {handleClick && <SwitchImplementationButton onClick={handleClick}>
          Use a Username and Password instead
        </SwitchImplementationButton>}
    </>;
};
export default RegisterPaychexFlexApi;