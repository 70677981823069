import { ContainerOverlay } from 'components/Container/ContainerOverlay';
import { LoadingPage } from './LoadingPage';
export const AuthorizeLoadingPage = ({
  provider,
  clientName
}: {
  provider: string;
  clientName?: string;
}) => {
  return <ContainerOverlay data-sentry-element="ContainerOverlay" data-sentry-component="AuthorizeLoadingPage" data-sentry-source-file="AuthorizeLoadingPage.tsx">
      <LoadingPage heading="Hang tight..." text={`We're connecting to ${provider}  ${clientName ? `and you'll be back to ${clientName} really soon` : ''} `} data-sentry-element="LoadingPage" data-sentry-source-file="AuthorizeLoadingPage.tsx" />
    </ContainerOverlay>;
};