// This will be used should there not be an override in session storage.
const DEFAULT_BACKEND = process.env.REACT_APP_BACKEND_ROUTE;
// The key in session
const SESSION_STORAGE_OVERRIDE_KEY = 'REACT_APP_BACKEND_ROUTE';

export const getBackendUrl = (): string => {
  const value = sessionStorage.getItem(SESSION_STORAGE_OVERRIDE_KEY);
  if (value) {
    try {
      // eslint-disable-next-line no-new
      new URL(value);
      return value;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('could not parse URL from session store', { err });
    }
  }

  // Use the default!
  if (!DEFAULT_BACKEND) {
    const msg = '(config error): no API URL specified!';
    throw new Error(msg);
  }
  return DEFAULT_BACKEND;
};
