import {
  ImplementationKind,
  PlainImplementationKind,
  toPlainImplementationKindMap,
} from '@finch-api/common/dist/internal/connect/authorize';
import {
  PayrollProviderId,
  RegisterImplementationProps,
} from 'constants/types';

import RegisterADPRunCredential from './ADPRun/RegisterADPRunCredential';
import RegisterADPWorkforceNowCredential from './ADPWorkforceNow/RegisterADPWorkforceNowCredential';
import RegisterBambooHRApi from './BambooHR/RegisterBambooHRApi';
import RegisterBambooHRCredential from './BambooHR/RegisterBambooHRCredential';
import RegisterBobApi from './Bob/RegisterBobApi';
import RegisterBobCredential from './Bob/RegisterBobCredential';
import RegisterDeelOAuth from './Deel/RegisterDeelOAuth';
import RegisterFactorialHROAuth from './FactorialHR/RegisterFactorialHROAuth';
import RegisterFinchCredential from './Finch/RegisterFinchCredential';
import RegisterGustoCredential from './Gusto/RegisterGustoCredential';
import RegisterGustoOAuth from './Gusto/RegisterGustoOAuth';
import RegisterHumaansApi from './Humaans/RegisterHumaansApi';
import RegisterHorizonPayrollCredential from './HorizonPayroll/RegisterHorizonPayrollCredential';
import RegisterInsperityApi from './Insperity/RegisterInsperityAPI';
import RegisterInsperityCredential from './Insperity/RegisterInsperityCredential';
import RegisterJustworksCredential from './Justworks/RegisterJustworksCredential';
import RegisterNamelyApi from './Namely/RegisterNamelyApi';
import RegisterNamelyCredential from './Namely/RegisterNamelyCredential';
import RegisterOysterHrOAuth from './OysterHR/RegisterOysterHrOAuth';
import RegisterPaychexFlexApi from './PaychexFlex/RegisterPaychexFlexApi';
import RegisterPaychexFlexCredential from './PaychexFlex/RegisterPaychexFlexCredential';
import RegisterPaycomApi from './Paycom/RegisterPaycomApi';
import RegisterPaycomCredential from './Paycom/RegisterPaycomCredential';
import RegisterPaycorOAuth from './Paycor/RegisterPaycorOAuth';
import RegisterPaylocityApi from './Paylocity/RegisterPaylocityApi';
import RegisterPaylocityCredential from './Paylocity/RegisterPaylocityCredential';
import RegisterPersonioApi from './Personio/RegisterPersonioApi';
import RegisterQuickbooksCredential from './Quickbooks/RegisterQuickbooksCredential';
import RegisterRipplingApi from './Rippling/RegisterRipplingApi';
import RegisterRipplingOAuth from './Rippling/RegisterRipplingOAuth';
import RegisterSageHRApi from './SageHR/RegisterSageHRApi';
import RegisterSaplingApi from './Sapling/RegisterSaplingApi';
import RegisterSequoiaOneCredential from './SequoiaOne/RegisterSequoiaOneCredential';
import RegisterSquarePayrollCredential from './SquarePayroll/RegisterSquarePayrollCredential';
import RegisterSquarePayrollOAuth from './SquarePayroll/RegisterSquarePayrollOAuth';
import RegisterTriNetApi from './TriNet/RegisterTriNetApi';
import RegisterUKGReadyApi from './UKGReady/RegisterUKGReadyApi';
import RegisterUltiProApi from './UltiPro/RegisterUltiProApi';
import RegisterWaveCredential from './Wave/RegisterWaveCredential';
import RegisterWorkdayApiToken from './Workday/RegisterWorkdayApiToken';
import RegisterWorkdayCredential from './Workday/RegisterWorkdayCredential';
import RegisterZenefitsApi from './Zenefits/RegisterZenefitsApi';
import RegisterZenefitsCredential from './Zenefits/RegisterZenefitsCredential';
import RegisterAlphaStaffApi from './AlphaStaff/RegisterAlphaStaffApi';
import RegisterAppliedBusinessSolutionsApi from './AppliedBusinessSolutions/RegisterAppliedBusinessSolutionsApi';
import RegisterHybridApiToken from './Hybrid/RegisterHybridApiToken';
import RegisterCounterpointHcmApiToken from './CounterpointHcm/RegisterCounterpointHcmApiToken';
import RegisterCeridianDayforceApi from './CeridianDayforce/RegisterCeridianDayforceApi';
import SignInGusto from './Gusto/SignInGusto';
import SignInPaylocityApi from './Paylocity/SignInPaylocityApi';
import SignInPaylocityCredential from './Paylocity/SignInPaylocityCredential';
import SignInJustworksCredential from './Justworks/SignInJustworksCredential';
import SignInSquareOAuth from './SquarePayroll/SignInSquareOAuth';
import SignInSquareCredential from './SquarePayroll/SignInSquareCredential';
import SignInSaplingApi from './Sapling/SignInSaplingApi';
import SignInSageHRApi from './SageHR/SignInSageHRApi';
import SignInSequoiaOneCredential from './SequoiaOne/SignInSequoiaOneCredential';
import SignInPaycomCredential from './Paycom/SignInPaycomCredential';
import SignInPaycomApi from './Paycom/SignInPaycomApi';
import { SignInAdpRunCredential } from './ADPRun/SignInAdpRunCredential';
import { SignInAdpWorkforceNowCredential } from './ADPWorkforceNow/SignInAdpWorkforceNowCredential';
import SignInWaveCredential from './Wave/SignInWaveCredential';
import SignInQuickbooksCredential from './Quickbooks/SignInQuickbooksCredential';
import SignInZenefitsCredential from './Zenefits/SignInZenefitsCredential';
import SignInUltiProApi from './UltiPro/SignInUltiProApi';
import SignInUKGReadyApi from './UKGReady/SignInUKGReadyApi';
import SignInTriNetApi from './TriNet/SignInTriNetApi';
import SignInWorkdayCredential from './Workday/SignInWorkdayCredential';
import SignInWorkdayApi from './Workday/SignInWorkdayApi';
import { SignInAlphaStaffApi } from './AlphaStaff/SignInAlphaStaffApi';
import { SignInBambooHrCredential } from './BambooHR/SignInBambooHrCredential';
import { SignInBambooHrApi } from './BambooHR/SignInBambooHrApi';
import { SignInBobApi } from './Bob/SignInBobApi';
import { SignInBobCredential } from './Bob/SignInBobCredential';
import SignInHumaansApi from './Humaans/SignInHumaansApi';
import SignInHorizonPayrollCredential from './HorizonPayroll/SignInHorizonPayrollCredential';
import SignInNamelyCredential from './Namely/SignInNamelyCredential';
import SignInNamelyApi from './Namely/SignInNamelyApi';
import SignInInsperityCredential from './Insperity/SignInInsperityCredential';
import SignInInsperityApi from './Insperity/SignInInsperityAPI';
import SignInPaychexFlexCredential from './PaychexFlex/SignInPaychexFlexCredential';
import SignInPaychexFlexApi from './PaychexFlex/SignInPaychexFlexApi';
import SignInPaycorOAuth from './Paycor/SignInPaycorOAuth';
import SignInCeridianDayforceApi from './CeridianDayforce/SignInCeridianDayforceApi';
import SignInPersonioApi from './Personio/SignInPersonioApi';
import SignInOysterHrOAuth from './OysterHR/SignInOysterHrOAuth';
import SignInAppliedBusinessSolutionsApi from './AppliedBusinessSolutions/SignInAppliedBusinessSolutionsApi';
import SignInHybridApi from './Hybrid/SignInHybridApi';
import SignInFinchCredential from './Finch/SignInFinchCredential';
import SignInFactorialHROAuth from './FactorialHR/SignInFactorialHROAuth';
import SignInDeelOAuth from './Deel/SignInDeelOAuth';
import SignInCounterpointHcmApi from './CounterpointHcm/SignInCounterpointHcmApi';
import SignInRipplingOAuth from './Rippling/SignInRipplingOAuth';
import SignInOnpayCredential from './Onpay/SignInOnpayCredential';
import SignInZenefitsApi from './Zenefits/SignInZenefitsApi';

// TEMP: used to store new design screens until the feature flag is removed
const newDesigns: Partial<{
  [k in PayrollProviderId]: Partial<{
    [k in PlainImplementationKind]:
      | ((props: RegisterImplementationProps) => JSX.Element)
      | null;
  }>;
}> = {
  [PayrollProviderId.ADP_RUN]: {
    [ImplementationKind.CREDENTIAL]: SignInAdpRunCredential,
  },
  [PayrollProviderId.ADP_WORKFORCE_NOW]: {
    [ImplementationKind.CREDENTIAL]: SignInAdpWorkforceNowCredential,
  },
  [PayrollProviderId.ALPHASTAFF]: {
    [ImplementationKind.API_TOKEN]: SignInAlphaStaffApi,
  },
  [PayrollProviderId.APPLIED_BUSINESS_SOLUTIONS]: {
    [ImplementationKind.API_TOKEN]: SignInAppliedBusinessSolutionsApi,
  },
  [PayrollProviderId.BAMBOO_HR]: {
    [ImplementationKind.CREDENTIAL]: SignInBambooHrCredential,
    [ImplementationKind.API_TOKEN]: SignInBambooHrApi,
    [ImplementationKind.OAUTH]: SignInBambooHrApi,
  },
  [PayrollProviderId.BOB]: {
    [ImplementationKind.API_TOKEN]: SignInBobApi,
    [ImplementationKind.CREDENTIAL]: SignInBobCredential,
  },
  [PayrollProviderId.CERIDIAN_DAYFORCE]: {
    [ImplementationKind.API_TOKEN]: SignInCeridianDayforceApi,
  },
  [PayrollProviderId.COUNTERPOINT_HCM]: {
    [ImplementationKind.API_TOKEN]: SignInCounterpointHcmApi,
  },
  [PayrollProviderId.DEEL]: {
    [ImplementationKind.API_TOKEN]: SignInDeelOAuth,
    [ImplementationKind.OAUTH]: SignInDeelOAuth,
  },
  [PayrollProviderId.FACTORIAL_HR]: {
    [ImplementationKind.OAUTH]: SignInFactorialHROAuth,
  },
  [PayrollProviderId.FINCH]: {
    [ImplementationKind.CREDENTIAL]: SignInFinchCredential,
  },
  [PayrollProviderId.GUSTO]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: SignInGusto,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.HORIZON_PAYROLL]: {
    [ImplementationKind.CREDENTIAL]: SignInHorizonPayrollCredential,
  },
  [PayrollProviderId.HUMAANS]: {
    [ImplementationKind.API_TOKEN]: SignInHumaansApi,
  },
  [PayrollProviderId.HYBRID]: {
    [ImplementationKind.API_TOKEN]: SignInHybridApi,
  },
  [PayrollProviderId.INSPERITY]: {
    [ImplementationKind.CREDENTIAL]: SignInInsperityCredential,
    [ImplementationKind.API_TOKEN]: SignInInsperityApi,
    [ImplementationKind.OAUTH]: SignInInsperityApi,
  },
  [PayrollProviderId.JUSTWORKS]: {
    [ImplementationKind.CREDENTIAL]: SignInJustworksCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.NAMELY]: {
    [ImplementationKind.CREDENTIAL]: SignInNamelyCredential,
    [ImplementationKind.API_TOKEN]: SignInNamelyApi,
    [ImplementationKind.OAUTH]: SignInNamelyApi,
  },
  [PayrollProviderId.ONPAY]: {
    [ImplementationKind.CREDENTIAL]: SignInOnpayCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.OYSTER_HR]: {
    [ImplementationKind.OAUTH]: SignInOysterHrOAuth,
  },
  [PayrollProviderId.PAYCHEX_FLEX]: {
    [ImplementationKind.CREDENTIAL]: SignInPaychexFlexCredential,
    [ImplementationKind.API_TOKEN]: SignInPaychexFlexApi,
    [ImplementationKind.OAUTH]: SignInPaychexFlexApi,
  },
  [PayrollProviderId.PAYCOM]: {
    [ImplementationKind.CREDENTIAL]: SignInPaycomCredential,
    [ImplementationKind.API_TOKEN]: SignInPaycomApi,
    [ImplementationKind.OAUTH]: SignInPaycomApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.PAYCOR]: {
    [ImplementationKind.API_TOKEN]: SignInPaycorOAuth,
    [ImplementationKind.OAUTH]: SignInPaycorOAuth,
  },
  [PayrollProviderId.PAYLOCITY]: {
    [ImplementationKind.CREDENTIAL]: SignInPaylocityCredential,
    [ImplementationKind.API_TOKEN]: SignInPaylocityApi,
    [ImplementationKind.OAUTH]: SignInPaylocityApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.PERSONIO]: {
    [ImplementationKind.API_TOKEN]: SignInPersonioApi,
    [ImplementationKind.OAUTH]: SignInPersonioApi,
  },
  [PayrollProviderId.QUICKBOOKS]: {
    [ImplementationKind.CREDENTIAL]: SignInQuickbooksCredential,
  },
  [PayrollProviderId.RIPPLING]: {
    [ImplementationKind.OAUTH]: SignInRipplingOAuth,
  },
  [PayrollProviderId.SAGE_HR]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: SignInSageHRApi,
    [ImplementationKind.OAUTH]: SignInSageHRApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.SAPLING]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: SignInSaplingApi,
    [ImplementationKind.OAUTH]: SignInSaplingApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.SEQUOIA_ONE]: {
    [ImplementationKind.CREDENTIAL]: SignInSequoiaOneCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.SQUARE_PAYROLL]: {
    [ImplementationKind.CREDENTIAL]: SignInSquareCredential,
    [ImplementationKind.API_TOKEN]: SignInSquareOAuth,
    [ImplementationKind.OAUTH]: SignInSquareOAuth,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.TRINET]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: SignInTriNetApi,
    [ImplementationKind.OAUTH]: SignInTriNetApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.UKG_READY]: {
    [ImplementationKind.API_TOKEN]: SignInUKGReadyApi,
  },
  [PayrollProviderId.ULTI_PRO]: {
    [ImplementationKind.API_TOKEN]: SignInUltiProApi,
    [ImplementationKind.OAUTH]: SignInUltiProApi,
  },
  [PayrollProviderId.WAVE]: {
    [ImplementationKind.CREDENTIAL]: SignInWaveCredential,
  },
  [PayrollProviderId.WORKDAY]: {
    [ImplementationKind.CREDENTIAL]: SignInWorkdayCredential,
    [ImplementationKind.API_TOKEN]: SignInWorkdayApi,
  },
  [PayrollProviderId.ZENEFITS]: {
    [ImplementationKind.CREDENTIAL]: SignInZenefitsCredential,
    [ImplementationKind.API_TOKEN]: SignInZenefitsApi,
  },
};

const providerToComponentMap: {
  [k in PayrollProviderId]: {
    [k in PlainImplementationKind]:
      | ((props: RegisterImplementationProps) => JSX.Element)
      | null;
  };
} = {
  // hris
  [PayrollProviderId.ADP_RUN]: {
    [ImplementationKind.CREDENTIAL]: RegisterADPRunCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.ADP_WORKFORCE_NOW]: {
    [ImplementationKind.CREDENTIAL]: RegisterADPWorkforceNowCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.APPLIED_BUSINESS_SOLUTIONS]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterAppliedBusinessSolutionsApi,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.BAMBOO_HR]: {
    [ImplementationKind.CREDENTIAL]: RegisterBambooHRCredential,
    [ImplementationKind.API_TOKEN]: RegisterBambooHRApi,
    [ImplementationKind.OAUTH]: RegisterBambooHRApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.BOB]: {
    [ImplementationKind.CREDENTIAL]: RegisterBobCredential,
    [ImplementationKind.API_TOKEN]: RegisterBobApi,
    [ImplementationKind.OAUTH]: RegisterBobApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.CERIDIAN_DAYFORCE]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterCeridianDayforceApi,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.COUNTERPOINT_HCM]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterCounterpointHcmApiToken,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.DEEL]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterDeelOAuth,
    [ImplementationKind.OAUTH]: RegisterDeelOAuth,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.FACTORIAL_HR]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: RegisterFactorialHROAuth,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.FINCH]: {
    [ImplementationKind.CREDENTIAL]: RegisterFinchCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.GUSTO]: {
    [ImplementationKind.CREDENTIAL]: RegisterGustoCredential,
    [ImplementationKind.API_TOKEN]: RegisterGustoOAuth,
    [ImplementationKind.OAUTH]: RegisterGustoOAuth,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.HUMAANS]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterHumaansApi,
    [ImplementationKind.OAUTH]: RegisterHumaansApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.HYBRID]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterHybridApiToken,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.HORIZON_PAYROLL]: {
    [ImplementationKind.CREDENTIAL]: RegisterHorizonPayrollCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.INSPERITY]: {
    [ImplementationKind.CREDENTIAL]: RegisterInsperityCredential,
    [ImplementationKind.API_TOKEN]: RegisterInsperityApi,
    [ImplementationKind.OAUTH]: RegisterInsperityApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.JUSTWORKS]: {
    [ImplementationKind.CREDENTIAL]: RegisterJustworksCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.NAMELY]: {
    [ImplementationKind.CREDENTIAL]: RegisterNamelyCredential,
    [ImplementationKind.API_TOKEN]: RegisterNamelyApi,
    [ImplementationKind.OAUTH]: RegisterNamelyApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.OYSTER_HR]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: RegisterOysterHrOAuth,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.ONPAY]: {
    [ImplementationKind.CREDENTIAL]: SignInOnpayCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.PAYCHEX_FLEX]: {
    [ImplementationKind.CREDENTIAL]: RegisterPaychexFlexCredential,
    [ImplementationKind.API_TOKEN]: RegisterPaychexFlexApi,
    [ImplementationKind.OAUTH]: RegisterPaychexFlexApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.PAYCOM]: {
    [ImplementationKind.CREDENTIAL]: RegisterPaycomCredential,
    [ImplementationKind.API_TOKEN]: RegisterPaycomApi,
    [ImplementationKind.OAUTH]: RegisterPaycomApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.PAYCOR]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterPaycorOAuth,
    [ImplementationKind.OAUTH]: RegisterPaycorOAuth,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.PAYLOCITY]: {
    [ImplementationKind.CREDENTIAL]: RegisterPaylocityCredential,
    [ImplementationKind.API_TOKEN]: RegisterPaylocityApi,
    [ImplementationKind.OAUTH]: RegisterPaylocityApi,

    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.PERSONIO]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterPersonioApi,
    [ImplementationKind.OAUTH]: RegisterPersonioApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.QUICKBOOKS]: {
    [ImplementationKind.CREDENTIAL]: RegisterQuickbooksCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.RIPPLING]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterRipplingApi,
    [ImplementationKind.OAUTH]: RegisterRipplingOAuth,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.SAGE_HR]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterSageHRApi,
    [ImplementationKind.OAUTH]: RegisterSageHRApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.SAPLING]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterSaplingApi,
    [ImplementationKind.OAUTH]: RegisterSaplingApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.SEQUOIA_ONE]: {
    [ImplementationKind.CREDENTIAL]: RegisterSequoiaOneCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.SQUARE_PAYROLL]: {
    [ImplementationKind.CREDENTIAL]: RegisterSquarePayrollCredential,
    [ImplementationKind.API_TOKEN]: RegisterSquarePayrollOAuth,
    [ImplementationKind.OAUTH]: RegisterSquarePayrollOAuth,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.TRINET]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterTriNetApi,
    [ImplementationKind.OAUTH]: RegisterTriNetApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.UKG_READY]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterUKGReadyApi,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.ULTI_PRO]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterUltiProApi,
    [ImplementationKind.OAUTH]: RegisterUltiProApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.WAVE]: {
    [ImplementationKind.CREDENTIAL]: RegisterWaveCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.WORKDAY]: {
    [ImplementationKind.CREDENTIAL]: RegisterWorkdayCredential,
    [ImplementationKind.API_TOKEN]: RegisterWorkdayApiToken,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.ZENEFITS]: {
    [ImplementationKind.CREDENTIAL]: RegisterZenefitsCredential,
    [ImplementationKind.API_TOKEN]: RegisterZenefitsApi,
    [ImplementationKind.OAUTH]: RegisterZenefitsApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.ALPHASTAFF]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: RegisterAlphaStaffApi,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
};

export const getProviderComponent = (
  payrollProviderId: PayrollProviderId,
  implementationKind: ImplementationKind,
  useNewDesign: boolean = false,
) => {
  const rawImplementationKind =
    toPlainImplementationKindMap[implementationKind];

  if (useNewDesign) {
    const component = newDesigns[payrollProviderId]?.[rawImplementationKind];

    if (component) {
      return component;
    }
  }

  return providerToComponentMap[payrollProviderId][rawImplementationKind];
};

// TODO: remove this function and downstream logic once the feature flag is removed
export const useNewProviderComponent = (
  payrollProviderId: PayrollProviderId,
  implementationKind: ImplementationKind,
  useNewDesign: boolean = false,
): boolean => {
  const rawImplementationKind =
    toPlainImplementationKindMap[implementationKind];

  if (useNewDesign) {
    const component = newDesigns[payrollProviderId]?.[rawImplementationKind];

    if (component) {
      return true;
    }
  }

  return false;
};
