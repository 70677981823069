import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import { getProviderFormLabel } from '../utils';
const SignInHybridApi = ({
  provider,
  client,
  isLoading,
  onSubmit,
  instructionsLink
}: RegisterImplementationProps) => {
  const instructions = <>
      <p>
        To connect your {provider.displayName} account, you must get your Client
        ID for <strong>{client.name}</strong>.
      </p>
    </>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} instructionsLink={instructionsLink} divider onSubmit={onSubmit} fieldNames={['instructionCheck', 'username']} render={({
    instructionCheck,
    username
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Client ID" id="username" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !username.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInHybridApi" data-sentry-source-file="SignInHybridApi.tsx" />;
};
export default SignInHybridApi;