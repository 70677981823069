import { ImplementationBenefitsMethod } from '@finch-api/common/dist/db';
import { RegisterProviderContainerProps } from 'constants/types';
import SignInGuide from 'pages/Authorize/SignIn/SignInGuide';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { ProviderAppShell } from '../ProviderAppShell';
const RegisterProviderContainer = ({
  client,
  provider,
  products,
  connection,
  hasBenefitsProduct,
  currentStep,
  implementationDetail,
  children,
  sandboxMode,
  error,
  setError
}: RegisterProviderContainerProps) => {
  const eligibleForAssistedBenefits =
  // only eligible for assisted benefits if there's benefits product
  hasBenefitsProduct &&
  // only eligible for assisted benefits if provider supports assisted benefits
  implementationDetail.benefitsMethod === ImplementationBenefitsMethod.ASSISTED &&
  // only eligible for assisted benefits if provider doesn't support accountant flow
  !implementationDetail.accountantSupported &&
  // only eligible when not in sandbox mode
  !sandboxMode &&
  // only eligible when connection's assisted status is not CONNECTED
  connection?.assistedStatus !== AccountStatus.CONNECTED;
  return <ProviderAppShell error={error} setError={setError} providerImplementation={implementationDetail} provider={provider} headerText={`Connect your ${provider.displayName} account`} data-sentry-element="ProviderAppShell" data-sentry-component="RegisterProviderContainer" data-sentry-source-file="RegisterProviderContainer.tsx">
      <SignInGuide client={client} products={products} provider={provider} currentStep={currentStep} enabled={eligibleForAssistedBenefits} data-sentry-element="SignInGuide" data-sentry-source-file="RegisterProviderContainer.tsx">
        {children}
      </SignInGuide>
    </ProviderAppShell>;
};
export default RegisterProviderContainer;