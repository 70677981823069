import { useRef, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import SignInBase from '../SignInBase';
import { getProviderFormLabel } from '../utils';
const {
  protocol,
  host
} = window.location;
const OYSTER_HR_REDIRECT_URI = `${protocol}//${host}/auth/oyster_hr`;
const SignInOysterHrOAuth = ({
  client,
  onSubmit,
  setError,
  onMockOAuth,
  provider
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [loading, setLoading] = useState(false);
  const externalWindowRef = useRef<Window | null>(null);
  const knownState = `oyster_hr-${uuidv1()}`;

  // use any for type until Finch common types can be updated
  const config = (client as any)?.oysterHrApiConfig;
  const {
    authUrl
  } = config;
  if (!config?.authUrl) {
    throw new Error('OysterHR API config is missing authUrl');
  }
  const closeWindow = () => {
    setLoading(false);
    externalWindowRef?.current?.close();
  };

  // Function to run when user initiates Oyster OAuth
  const handleSubmit = async () => {
    setLoading(true);
    const didMockOAuth = await onMockOAuth();
    if (didMockOAuth) {
      setLoading(false);
      return;
    }
    // Setup the request to initiate Oyster OAuth
    const url = new URL(authUrl);
    url.searchParams.set('state', knownState);
    externalWindowRef.current = window.open(url.toString(), 'popup', 'popup=true');
    if (!externalWindowRef.current || externalWindowRef.current.closed) return;
    const checkWindowInterval = setInterval(async () => {
      if (externalWindowRef?.current?.closed) {
        clearInterval(checkWindowInterval);
      } else {
        try {
          const urlParams = new URLSearchParams(externalWindowRef?.current?.location.search);
          const receivedState = urlParams.get('state');
          if (receivedState !== knownState) return;
          const code = urlParams.get('code');
          const error = urlParams.get('error');
          if (code) {
            closeWindow();
            await onSubmit({
              clientCode: code,
              providerRedirectUri: OYSTER_HR_REDIRECT_URI
            });
          }
          if (error) {
            setError({
              message: error,
              status: null
            });
            closeWindow();
          }
        } catch (e) {
          // ignore dom exception errors
          if (e instanceof DOMException) {
            return;
          }
          setError({
            message: JSON.stringify(e),
            status: null
          });
          closeWindow();
        }
      }
    }, 500);
  };
  const instructions = <p>
      Oyster HR authentication will continue in a new window. Please make sure
      to allow popups and finish authenticating through Oyster HR in the new
      window.
    </p>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} data-sentry-element="SignInBase" data-sentry-component="SignInOysterHrOAuth" data-sentry-source-file="SignInOysterHrOAuth.tsx">
      <div style={{
      marginTop: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
        <LoaderButton isLoading={loading} disabled={loading} onClick={handleSubmit} data-sentry-element="LoaderButton" data-sentry-source-file="SignInOysterHrOAuth.tsx">
          Connect
        </LoaderButton>
      </div>
    </SignInBase>;
};
export default withAuthorizeContext(SignInOysterHrOAuth);