import { ContainerOverlay } from 'components/Container/ContainerOverlay';
import GenericError from 'pages/Authorize/Errors/GenericError';
export const CriticalAuthError = ({
  onButtonClick,
  headerText,
  errorMessage,
  instructionsLink,
  buttonLabel,
  errorCode
}: {
  onButtonClick?: () => void;
  headerText?: string;
  errorMessage?: string;
  instructionsLink?: string;
  buttonLabel?: string;
  errorCode?: string;
}) => <ContainerOverlay data-sentry-element="ContainerOverlay" data-sentry-component="CriticalAuthError" data-sentry-source-file="CriticalAuthError.tsx">
    <GenericError showFinchLogo={false} buttonLabel={buttonLabel} title={headerText || 'Something went wrong'} onClick={onButtonClick} instructionsLink={instructionsLink} errorCode={errorCode} data-sentry-element="GenericError" data-sentry-source-file="CriticalAuthError.tsx">
      {errorMessage || 'An unexpected error occurred. Please try again.'}
    </GenericError>
  </ContainerOverlay>;