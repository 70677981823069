import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import { getProviderFormLabel } from '../utils';
export function SignInAdpRunCredential({
  isLoading,
  onSubmit,
  client,
  provider,
  instructionsLink
}: RegisterImplementationProps) {
  const instructions: JSX.Element = <>
      <p>
        By providing your {provider.displayName} credentials, you&apos;re
        enabling <strong>{client.name}</strong> to retrieve data through a Third
        Party Administrator.
      </p>
    </>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} instructionsLink={instructionsLink} onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => {
    return <>
            <MainContainer useNewDesign>
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="User ID" id="username" />
              <PasswordInput reset={{
          uri: new URL('https://netsecure.adp.com/ilink/pub/smsess/v3/forgot/theme.jsp'),
          providerDisplayName: provider.displayName
        }} value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !username.value || !password.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInAdpRunCredential" data-sentry-source-file="SignInAdpRunCredential.tsx" />;
}