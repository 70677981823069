import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import LoaderButton from 'components/Button/LoaderButton';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
import { AppLink } from 'components/Link/AppLink';
import { getProviderFormLabel } from '../utils';
const RegisterTriNetApi = ({
  client,
  provider,
  isLoading,
  onSubmit
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      Please follow the{' '}
      <AppLink target="_blank" href="https://developer.tryfinch.com/employer-resources/Trinet" rel="noopener noreferrer">
        TriNet instructions
      </AppLink>{' '}
      to get credentials. When prompted to select an integration in the TriNet
      dashboard, select <strong>{client.name}</strong>.
    </>, <>
      Once you have received your credentials, return to this page and input the
      information below.
    </>];
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterTriNetApi.tsx">
        To connect your TriNet account, you must create an API token for{' '}
        <strong>{client.name}</strong>.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterTriNetApi.tsx" />
      <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['companyId', 'providerClientId', 'providerClientSecret']} render={({
      companyId,
      providerClientId,
      providerClientSecret
    }) => <>
            <MainContainer>
              <Input id="companyId" value={companyId.value} onChange={event => companyId.onChange(event.target.value)} invalid={companyId.invalid} label="Company ID" />
              <Input id="providerClientId" value={providerClientId.value} onChange={event => providerClientId.onChange(event.target.value)} invalid={providerClientId.invalid} label="Client ID" />
              <PasswordInput id="clientSecret" value={providerClientSecret.value} onChange={event => providerClientSecret.onChange(event.target.value)} invalid={providerClientSecret.invalid} label="Client Secret" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterTriNetApi.tsx" />
    </>;
};
export default RegisterTriNetApi;