import styled from 'styled-components';
import COLORS from 'constants/colors';
import FONTS from 'constants/fonts';
interface LinkButtonProps {
  onClick: any;
  children: React.ReactNode;
}
interface SwitchImplementationButtonProps extends LinkButtonProps {
  useNewDesign?: boolean;
}
const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 2px;
  color: ${COLORS.GRAY.GRAY_600};
  cursor: pointer;
  line-height: 1;
  text-align: left;
  padding: 4px 0;
  font-size: ${FONTS.P};
  text-decoration-line: underline;

  &:hover,
  &:focus {
    color: ${COLORS.GRAY.GRAY_900};
  }
`;
export const StyledSwitchImplementationButtonV1 = styled(StyledButton)`
  position: absolute;
  bottom: 12px;
  width: 88%;
  text-align: center;
`;
export const StyledSwitchImplementationButton = styled(StyledButton)`
  align-self: center;
  margin-bottom: 12px;
`;
export const LinkButton = (props: LinkButtonProps) => <StyledButton type="button" {...props} data-sentry-element="StyledButton" data-sentry-component="LinkButton" data-sentry-source-file="LinkButton.tsx" />;
export const SwitchImplementationButton = ({
  useNewDesign,
  ...props
}: SwitchImplementationButtonProps) => {
  if (useNewDesign) {
    return <StyledSwitchImplementationButton type="button" {...props} />;
  }
  return <StyledSwitchImplementationButtonV1 type="button" {...props} data-sentry-element="StyledSwitchImplementationButtonV1" data-sentry-component="SwitchImplementationButton" data-sentry-source-file="LinkButton.tsx" />;
};