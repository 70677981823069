import { useMemo, useState } from 'react';
import { ImplementationKind, isAssistedImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { ImplementationDetail, ProviderConfig } from '@finch-api/common/dist/internal/connect/validate';
import { ConnectError } from '../types';
const useImplementations = (opts: {
  provider: ProviderConfig;
  setError: (error: ConnectError | null) => void;
  idpRedirect: boolean;
}): {
  implementationKind: ImplementationKind;
  implementationDetail?: ImplementationDetail;
  onSwitchImplementationKind?: () => void | undefined;
} => {
  const {
    provider,
    setError,
    idpRedirect
  } = opts;
  const implementations = useMemo(() =>
  // take out assisted impls as consideration
  provider.implementations.filter(impl => {
    if (isAssistedImplementationKind(provider.defaultImplementation) && provider.implementations.length === 1) {
      return true;
    }
    return !isAssistedImplementationKind(impl.kind as ImplementationKind);
  }), [provider]);

  // There's a chance that the default implementation kind is assisted due to the row orders inside our database
  // but we remove the assisted implementations above so when we try to find and implementation it fails and broke the connect
  const defaultImplementationKind: ImplementationKind = isAssistedImplementationKind(provider.defaultImplementation) ? implementations[0].kind as ImplementationKind : provider.defaultImplementation;

  // when there's more than 1 implementation, we need to consider default impl
  const [implementationKind, setImplementationKind] = useState<ImplementationKind>(defaultImplementationKind);
  if (idpRedirect) {
    const oauthImplementation = implementations.find(impl => impl.kind === ImplementationKind.OAUTH);
    if (!oauthImplementation) {
      throw new Error('No OAuth implementation found');
    }
    return {
      implementationKind: oauthImplementation.kind as ImplementationKind,
      onSwitchImplementationKind: undefined,
      implementationDetail: oauthImplementation
    };
  }

  // when there's only 1 implementation, we don't care about default impl anymore
  // we return the only implementation
  if (implementations.length === 1) {
    const currentImplementation = implementations[0];
    return {
      implementationKind: currentImplementation.kind as ImplementationKind,
      onSwitchImplementationKind: undefined,
      implementationDetail: currentImplementation
    };
  }
  const currentImplementationDetail = implementations.find(impl => impl.kind === implementationKind);
  const onSwitchImplementationKind = (): void | undefined => {
    setError(null);
    const currentImplementationKindIndex = implementations.findIndex(impl => impl.kind === implementationKind);
    const nextImplementationKindIndex = (currentImplementationKindIndex + 1) % implementations.length;
    const nextImplementationKind = implementations[nextImplementationKindIndex].kind as ImplementationKind;
    return setImplementationKind(nextImplementationKind);
  };
  return {
    implementationKind,
    onSwitchImplementationKind,
    implementationDetail: currentImplementationDetail
  };
};
export default useImplementations;