import { RegisterImplementationProps } from 'constants/types';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import MainContainer from 'components/Container/MainContainer';
import Input from 'components/Input/Input';
import LoaderButton from 'components/Button/LoaderButton';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import { AppLink } from 'components/Link/AppLink';
import { getProviderFormLabel } from '../utils';
const RegisterHybridApiToken = ({
  provider,
  isLoading,
  onSubmit
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      Please follow the instructions{' '}
      <AppLink target="_blank" href="https://developer.tryfinch.com/employer-resources/iSolved" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      to get the Client ID from the Hybrid website.
    </>, <>
      Once you have the Client ID, return to this page and input the information
      below.
    </>];
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterHybridApiToken.tsx">
        To connect your Hybrid account, you must get the Client ID from the
        Hybrid website.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterHybridApiToken.tsx" />

      <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['username']} render={({
      username
    }) => <>
            <MainContainer>
              <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Client ID on Hybrid" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterHybridApiToken.tsx" />
    </>;
};
export default RegisterHybridApiToken;