import { Category } from '@finch-api/common/dist/internal/connect/authorize';

export const EMPLOYER_PREFIX = 'employer:';

// eslint-disable-next-line import/prefer-default-export
export const productsConfig: {
  [key: string]: {
    [key: string]: {
      permissionText: string;
    };
  };
} = {
  [Category.HRIS]: {
    company: {
      permissionText: 'Read company data, including bank account data',
    },
    directory: {
      permissionText: 'Read company directory and organization structure',
    },
    individual: {
      permissionText:
        'Read individual data, excluding income and employment data',
    },
    employment: {
      permissionText: 'Read individual employment and income data',
    },
    // this is not a real mode from backend
    // only happen when the client request us to not show income with employment endpoint
    // list: corporate merch, secureframe, sequoia, tangelo, tava health, vanta
    employment_without_income: {
      permissionText: 'Read individual employment data',
    },
    payment: {
      permissionText:
        'Read payroll and contractor related payments by the company',
    },
    pay_statement: {
      permissionText: 'Read detailed pay statements for each employee',
    },
    tax: {
      permissionText: 'Read employee tax withholding information',
    },
    filing: {
      permissionText: 'File forms for the company',
    },
    ssn: {
      permissionText: 'Read SSNs of individuals in the company',
    },
    earnings_summary: {
      permissionText: 'Read basic employee pay statement information',
    },
    deduction: {
      permissionText:
        'Manage and process deductions on individuals within a company',
    },
    benefits: {
      permissionText:
        'Manage and process benefits on individuals within a company',
    },
    documents: {
      permissionText: 'Read company documents',
    },
  },
};
