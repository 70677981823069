import FONTS from 'constants/fonts';
import styled from 'styled-components';
const StyledInstructionHeader = styled.div`
  margin-bottom: 16px;
  font-size: ${FONTS.H5};

  strong {
    font-weight: 500;
  }
`;
interface InstructionHeaderProps {
  children: React.ReactNode;
}
const InstructionHeader = ({
  children
}: InstructionHeaderProps) => <StyledInstructionHeader data-sentry-element="StyledInstructionHeader" data-sentry-component="InstructionHeader" data-sentry-source-file="InstructionHeader.tsx">{children}</StyledInstructionHeader>;
export default InstructionHeader;