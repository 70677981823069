import styled from 'styled-components';
import COLORS from 'constants/colors';
import checkmarkIcon from 'assets/icons/checkmark.svg';
export const StyledCheckbox = styled.div`
  color: ${COLORS.GRAY.GRAY_900};
  display: flex;
  align-items: center;
  position: relative;
`;
export const StyledInput = styled.input`
  height: 18px;
  width: 18px;
  margin: 0px;
  appearance: none;

  position: relative;
  background-color: ${COLORS.WHITE};
  border: solid 1px ${COLORS.GRAY.GRAY_400};
  border-radius: 4px;
  cursor: pointer;
  padding: 1px;

  &:checked:after {
    content: '';
    background-image: url(${checkmarkIcon});
    background-color: ${props => props.color};
    border: solid 1px ${props => props.color};
    border-radius: 4px;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  cursor: ${(props: {
  disabled: boolean;
}) => props.disabled ? 'not-allowed' : 'pointer'};
`;
export const StyledLabel = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 8px;
  line-height: 20px;
  cursor: ${(props: {
  disabled: boolean;
}) => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${(props: {
  disabled: boolean;
}) => props.disabled ? 0.6 : 1};
  font-size: 14px;
`;
const Checkbox = ({
  label,
  id,
  color,
  onChange,
  checked,
  checkboxRef,
  disabled = false
}: {
  label: string;
  id: string;
  color: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
  checkboxRef?: React.RefObject<HTMLInputElement>;
  disabled?: boolean;
}) => <StyledCheckbox data-sentry-element="StyledCheckbox" data-sentry-component="Checkbox" data-sentry-source-file="CheckboxInput.tsx">
    <StyledInput type="checkbox" id={id} onChange={e => onChange(e.target.checked)} checked={checked} disabled={disabled} color={color} ref={checkboxRef} data-sentry-element="StyledInput" data-sentry-source-file="CheckboxInput.tsx" />
    <StyledLabel htmlFor={id} disabled={disabled} data-sentry-element="StyledLabel" data-sentry-source-file="CheckboxInput.tsx">
      {label}
    </StyledLabel>
  </StyledCheckbox>;
Checkbox.defaultProps = {
  disabled: false
};
export default Checkbox;