import styled from 'styled-components';
import COLORS from 'constants/colors';
import { ButtonHTMLAttributes } from 'react';
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: string;
  isLoading?: boolean;
  disabled?: boolean;
  margin?: string;
}
const Button = styled.button<ButtonProps>`
  position: relative;
  background: none;
  border: none;
  font-size: inherit;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${COLORS.WHITE};
  background-color: ${props => props.color};
  cursor: pointer;

  ${props => props.margin && `margin: ${props.margin};`}

  ${props => !props.isLoading && props.disabled && `background-color: ${COLORS.GRAY.GRAY_400};`}
`;
Button.defaultProps = {
  type: 'submit',
  isLoading: false,
  disabled: false
};
export default Button;