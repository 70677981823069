import Input, { InputProps } from './Input';
type RequiredProps = 'value' | 'onChange';
type TokenInputProps = Partial<Omit<InputProps, RequiredProps>> & Pick<InputProps, RequiredProps>;
const TokenInput = ({
  id = 'apiToken',
  label = 'API Token',
  invalid = false,
  ...props
}: TokenInputProps) => <Input id={id} label={label} invalid={invalid} {...props} data-sentry-element="Input" data-sentry-component="TokenInput" data-sentry-source-file="TokenInput.tsx" />;
TokenInput.defaultProps = {
  ...Input.defaultProps,
  id: 'apiToken',
  label: 'API Token',
  invalid: false
};
export default TokenInput;