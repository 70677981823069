import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import SignInDisclaimer from 'components/Text/SignInDisclaimer';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import MainContainer from 'components/Container/MainContainer';
import InstructionHeader from '../../../../components/Instruction/InstructionHeader';
import { ResetPasswordOnProviderLink } from 'components/Link/ResetPasswordOnProviderLink';
import { AppLink } from 'components/Link/AppLink';
import { getProviderFormLabel } from '../utils';
const RegisterZenefitsCredential = ({
  provider,
  isLoading,
  onSubmit,
  handleClick,
  sandboxMode
}: RegisterImplementationProps) => <>
    {sandboxMode === 'provider' && <InstructionHeader>
        Sign up for a free Zenefits account{' '}
        <AppLink href="https://www.zenefits.com/learn/trial-signup/" target="_blank" rel="noreferrer">
          on their website
        </AppLink>{' '}
        and connect it here.
      </InstructionHeader>}
    <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => <>
          <MainContainer>
            <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Email Address" autoFocus />
            <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
            <ResetPasswordOnProviderLink href="https://secure.zenefits.com/public/#/contact-us/login-issues" provider={provider.displayName} />
          </MainContainer>
          <SignInDisclaimer style={{
      marginBottom: '8px'
    }}>
            A third-party administrator may be added.
          </SignInDisclaimer>
          <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit">
            Connect
          </LoaderButton>
        </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterZenefitsCredential.tsx" />
    {handleClick && <SwitchImplementationButton onClick={handleClick}>
        Enter an API Token instead
      </SwitchImplementationButton>}
  </>;
export default RegisterZenefitsCredential;