import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import Container from 'components/Container/Container';
import LoadingSpinner from '../LoadingSpinner';
import ManualInstruction from './ManualInstruction';
import { authorize } from 'services/auth';
import { getStatusCode } from '../SignIn/utils';

/**
 * The component is only shown when we want to show the user the instructions to manually add an accountant.
 * This is shown when the user's credentials worked but we couldn't automatically add an accountant
 */
const AssistedAccountantInstructions = ({
  client,
  payrollProvider,
  providersConfig,
  providerImplementation,
  handleAuthorizeResponse,
  products,
  error,
  setError,
  handleStateRedirect,
  clientName,
  connectionId,
  currentStep,
  clientId,
  redirectUri,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  sandbox,
  appType,
  implementationHasAssistedBenefits
}: AuthorizeContextValue) => {
  if (!client) return <Container>{!client && <LoadingSpinner />}</Container>;
  if (!payrollProvider) throw new Error('no payroll provider');
  if (!providersConfig) throw new Error('no providers config');
  if (!providerImplementation) throw new Error('no provider implementation');
  if (!products) throw new Error('no products');
  const onSubmit = async () => {
    setError(null);
    try {
      const authorizeResponse = await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        sessionKey,
        currentBrowserSessionKey,
        state,
        category,
        step: currentStep,
        payrollProviderId: payrollProvider.id,
        providerImplementationId: providerImplementation.id,
        sandbox,
        appType,
        implementationHasAssistedBenefits
      });
      handleAuthorizeResponse(authorizeResponse);
    } catch (err: any) {
      setError({
        message: err.message || 'Unexpected error has occurred. Please try again.',
        status: getStatusCode(err),
        finchCode: err.response?.data?.finch_code
      });
    }
  };
  return <ManualInstruction client={client} providerImplementation={providerImplementation} error={error} setError={setError} payrollProvider={payrollProvider} onSubmit={onSubmit} handleStateRedirect={handleStateRedirect} data-sentry-element="ManualInstruction" data-sentry-component="AssistedAccountantInstructions" data-sentry-source-file="AssistedAccountantInstructions.tsx" />;
};
export default withAuthorizeContext(AssistedAccountantInstructions);