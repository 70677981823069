import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getBackendUrl } from '../../../services/get-backend-url';

type OAuthState = {
  sessionKey: string;
};

export const useUpdateOAuthState = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const oAuthState = {
    state: queryParams.get('state'),
    code: queryParams.get('code'),
    error: queryParams.get('error'),
  };

  const sessionKey =
    oAuthState.state &&
    (JSON.parse(oAuthState.state || '') as OAuthState)?.sessionKey;

  const { isLoading, error: postError } = useQuery({
    queryKey: ['oauth_state_update', sessionKey],
    queryFn: async () => {
      const response = await axios.post(
        `${getBackendUrl()}/auth/oauth/state/${sessionKey}`,
        { oAuthState },
      );

      window.close();

      return response;
    },
    refetchOnWindowFocus: false,
    enabled: !!sessionKey,
  });

  return { isLoading, postError };
};
