import COLORS from 'constants/colors';
import FONTS from 'constants/fonts';
import styled from 'styled-components';
import Input, { InputProps } from './Input';
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  box-sizing: border-box;
  margin-bottom: 8px;
`;
const Field = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  > * {
    width: 100%;
  }
`;
const Addon = styled.div`
  justify-content: center;
  box-sizing: border-box;
  padding: 16px 16px 0px 16px;
  background-color: ${COLORS.GRAY.GRAY_200};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid ${COLORS.GRAY.GRAY_400};
  border-left: 0;
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  color: #999999;
`;
const Container = styled.div`
  padding-bottom: 8px;
`;
const Example = styled.div`
  box-sizing: border-box;
  width: 100%;
  font-size: ${FONTS.P}; // NOTE: (@jordanbrauer) will need to increase with new labels/input font sizes with new designs
  font-family: Inter;
  text-align: left;
  color: ${COLORS.GRAY.GRAY_600};
  font-weight: 400;

  strong {
    font-weight: 600;
    color: ${COLORS.BLACK};
  }
`;
export function SubdomainInput({
  domain,
  ...rest
}: InputProps & {
  domain: string;
}) {
  return <Container data-sentry-element="Container" data-sentry-component="SubdomainInput" data-sentry-source-file="SubdomainInput.tsx">
      <Wrapper data-sentry-element="Wrapper" data-sentry-source-file="SubdomainInput.tsx">
        <Field data-sentry-element="Field" data-sentry-source-file="SubdomainInput.tsx">
          <Input {...rest} wrapperPaddingBottom={0} style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          marginBottom: 0,
          background: COLORS.WHITE,
          border: `1px solid ${COLORS.GRAY.GRAY_400}`,
          borderRight: 0,
          borderLeft: `1px solid ${COLORS.GRAY.GRAY_400}`,
          borderColor: COLORS.GRAY.GRAY_400,
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
          fontWeight: 400,
          color: COLORS.GRAY.GRAY_900,
          width: '100%'
        }} data-sentry-element="Input" data-sentry-source-file="SubdomainInput.tsx" />
        </Field>
        <Addon data-sentry-element="Addon" data-sentry-source-file="SubdomainInput.tsx">
          <div style={{
          display: 'flex'
        }}>.{domain}</div>
        </Addon>
      </Wrapper>
      <Example data-sentry-element="Example" data-sentry-source-file="SubdomainInput.tsx">
        Example: https://<strong>subdomain</strong>.{domain}
      </Example>
    </Container>;
}