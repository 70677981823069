import styled from 'styled-components';
import FONTS from 'constants/fonts';
import COLORS from 'constants/colors';
const InstructionContainer = styled.div`
  font-size: ${FONTS.H5};
  margin-bottom: 16px;
  border: 1px solid ${COLORS.GRAY.GRAY_400};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-height: 120px;
`;
export default InstructionContainer;