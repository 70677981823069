import { Heading, Spinner, Stack, Text } from '@chakra-ui/react';
import COLORS from 'constants/colors';
export const LoadingPage = ({
  heading,
  text
}: {
  heading: string;
  text: string;
}) => {
  return <Stack px="6" gap="6" py="120px" align="center" data-sentry-element="Stack" data-sentry-component="LoadingPage" data-sentry-source-file="LoadingPage.tsx">
      <Spinner boxSize="64px" borderWidth="4px" color={COLORS.FINCH.PURPLE} data-sentry-element="Spinner" data-sentry-source-file="LoadingPage.tsx" />
      <Heading fontWeight="medium" data-sentry-element="Heading" data-sentry-source-file="LoadingPage.tsx">{heading}</Heading>
      <Text textAlign="center" data-sentry-element="Text" data-sentry-source-file="LoadingPage.tsx">{text}</Text>
    </Stack>;
};