import { ContainerOverlay } from 'components/Container/ContainerOverlay';
import GenericError from 'pages/Authorize/Errors/GenericError';
export const AuthFallbackThresholdError = ({
  providerName,
  onNext
}: {
  providerName: string;
  onNext: () => void;
}) => <ContainerOverlay data-sentry-element="ContainerOverlay" data-sentry-component="AuthFallbackThresholdError" data-sentry-source-file="AuthFallbackThresholdError.tsx">
    <GenericError buttonLabel="Connect manually" isSecondaryButton title="Let's try this again" onClick={onNext} data-sentry-element="GenericError" data-sentry-source-file="AuthFallbackThresholdError.tsx">
      We&apos;re currently experiencing connection issues with {providerName}.
      Please try to connect manually.
    </GenericError>
  </ContainerOverlay>;