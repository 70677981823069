import { Heading, Text, Stack } from '@chakra-ui/react';
import { AppShellButton } from 'components/Button/AppShellButton';
import { ContainerOverlay } from 'components/Container/ContainerOverlay';
import { WarningIcon } from 'components/Icons/BigWarningIcon';
export const ExitPage = ({
  onClose,
  onGoBack
}: {
  onClose: () => void;
  onGoBack: () => void;
}) => {
  return <ContainerOverlay isExitIntent data-sentry-element="ContainerOverlay" data-sentry-component="ExitPage" data-sentry-source-file="ExitPage.tsx">
      <Stack p="6" h="full" justify="space-between" data-sentry-element="Stack" data-sentry-source-file="ExitPage.tsx">
        <Stack px="6" gap="6" py="40px" align="center" data-sentry-element="Stack" data-sentry-source-file="ExitPage.tsx">
          <WarningIcon data-sentry-element="WarningIcon" data-sentry-source-file="ExitPage.tsx" />
          <Heading fontWeight="medium" data-sentry-element="Heading" data-sentry-source-file="ExitPage.tsx">Are you sure?</Heading>
          <Text textAlign="center" data-sentry-element="Text" data-sentry-source-file="ExitPage.tsx">
            Your progress will be lost if you leave.
          </Text>
        </Stack>
        <Stack gap="4" data-sentry-element="Stack" data-sentry-source-file="ExitPage.tsx">
          <AppShellButton onClick={onClose} data-sentry-element="AppShellButton" data-sentry-source-file="ExitPage.tsx">Yes, leave</AppShellButton>
          <AppShellButton variant="outline" onClick={onGoBack} data-sentry-element="AppShellButton" data-sentry-source-file="ExitPage.tsx">
            No, go back
          </AppShellButton>
        </Stack>
      </Stack>
    </ContainerOverlay>;
};