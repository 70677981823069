import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
export function SignInAdpWorkforceNowCredential({
  isLoading,
  onSubmit,
  client,
  provider
}: RegisterImplementationProps) {
  const instructions: JSX.Element = <>
      <p>
        <strong>{client.name}</strong> will be added as a Third-Party
        Administrator to retrieve data.
      </p>
    </>;
  return <SignInBase formLabel={`${provider.displayName} Sign In Form`} instructions={instructions} onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => {
    return <>
            <MainContainer useNewDesign>
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Username" id="username" />
              <PasswordInput reset={{
          uri: new URL('https://netsecure.adp.com/ilink/pub/smsess/v3/forgot/theme.jsp'),
          providerDisplayName: provider.displayName
        }} value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !username.value || !password.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInAdpWorkforceNowCredential" data-sentry-source-file="SignInAdpWorkforceNowCredential.tsx" />;
}