import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import LoaderButton from 'components/Button/LoaderButton';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
import { AppLink } from 'components/Link/AppLink';
import { getProviderFormLabel } from '../utils';
const RegisterSageHRApi = ({
  client,
  provider,
  isLoading,
  onSubmit,
  sandboxMode
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      Click on your username on the top right corner of the screen. Then click
      on <strong>Settings</strong>.
    </>, <>
      Navigate to <strong>Integrations</strong> under the setting tab. Then
      click <strong>API</strong>.
    </>, <>
      Click <strong>Enable API Access</strong>.
    </>, <>Copy the generated API key, and paste it below.</>];
  if (sandboxMode === 'provider') {
    instructions.unshift(<>
        <AppLink href="https://developer.tryfinch.com/docs/documentation/pzie4zvmok31z-provider-sandboxes#sage" target="_blank" rel="noreferrer">
          Follow the setup instructions
        </AppLink>{' '}
        to get a test account for Sage.
      </>, <>
        After logging in to your account,{' '}
        <strong>hit &quot;Start Fresh&quot; at the top of the page</strong>
      </>);
  }
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterSageHRApi.tsx">
        To connect your Sage HR account, you must create an API token for{' '}
        <strong>{client.name}</strong>.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterSageHRApi.tsx" />
      <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['subdomain', 'apiToken']} render={({
      subdomain,
      apiToken
    }) => <>
            <MainContainer>
              <Input id="subdomain" value={subdomain.value} onChange={event => subdomain.onChange(event.target.value)} invalid={subdomain.invalid} label="Subdomain (from https://<subdomain>.sagehr.hr)" />
              <Input id="apiToken" value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label="API Key" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterSageHRApi.tsx" />
    </>;
};
export default RegisterSageHRApi;