import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import { apiTokenInstructions, getProviderFormLabel } from '../utils';
const SignInPaylocityApi = ({
  provider,
  client,
  isLoading,
  onSubmit,
  handleClick,
  instructionsLink
}: RegisterImplementationProps) => {
  const instructions = apiTokenInstructions(provider.displayName, client.name);
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} instructionsLink={instructionsLink} divider onSubmit={onSubmit} fieldNames={['instructionCheck', 'companyId', 'clientId', 'clientSecret']} render={({
    instructionCheck,
    companyId,
    clientId,
    clientSecret
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <Input value={companyId.value} onChange={event => companyId.onChange(event.target.value)} invalid={companyId.invalid} label="Company ID" id="companyId" />
              <Input value={clientId.value} onChange={event => clientId.onChange(event.target.value)} invalid={clientId.invalid} label="Client ID" id="clientId" />
              <Input value={clientSecret.value} onChange={event => clientSecret.onChange(event.target.value)} invalid={clientSecret.invalid} label="Client Secret" id="clientSecret" />
            </MainContainer>
            {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
                Use a Username and Password instead
              </SwitchImplementationButton>}
            <LoaderButton isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !companyId.value || !clientId.value || !clientSecret.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInPaylocityApi" data-sentry-source-file="SignInPaylocityApi.tsx" />;
};
export default SignInPaylocityApi;