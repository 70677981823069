import { ComponentProps } from 'react';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import NoticeMessage from '../Messages/Notice';
import styled from 'styled-components';
import { InstructionCallout } from 'components/Instruction/InstructionCallout';
import COLORS from 'constants/colors';
const Divider = styled.hr<{
  spacing?: 'sm' | 'md';
}>`
  width: 100%;
  background-color: #d9d9d9;
  height: 1px;
  border-width: 0;
  margin: 0;
  margin-bottom: ${props => props.spacing === 'md' ? 8 : 0}px;
`;
const Instructions = styled.div<{
  spacing?: 'sm' | 'md';
}>`
  p {
    color: var(--Updated-Finch-Black, #161616);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    margin: 0;
    margin-bottom: ${props => props.spacing === 'md' ? 24 : 16}px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    font-family: Inter, sans-serif;
    font-weight: 600;
    text-decoration: underline;
    color: ${COLORS.BLACK};
  }
  strong {
    font-weight: 600;
  }
`;
type SignInBaseProps = {
  instructions?: JSX.Element | string;
  instructionsLink?: string;
  divider?: boolean;
  children?: React.ReactNode;
  notice?: string;
  spacing?: 'sm' | 'md';
} & Partial<ComponentProps<typeof MultiValueForm>>;
const SignInBase = ({
  instructions,
  divider = false,
  notice,
  instructionsLink,
  fieldNames,
  render,
  onSubmit,
  children,
  spacing = 'sm',
  formLabel
}: SignInBaseProps): JSX.Element => {
  return <>
      {typeof notice === 'string' && <NoticeMessage>{notice}</NoticeMessage>}
      {instructions && <Instructions spacing={spacing}>{instructions}</Instructions>}
      {instructionsLink && <InstructionCallout link={instructionsLink} />}
      {divider && <Divider spacing={spacing} />}
      {fieldNames && render && onSubmit && <MultiValueForm formLabel={formLabel ?? 'Sign In Form'} onSubmit={onSubmit} fieldNames={fieldNames} render={render} />}
      {children}
    </>;
};
SignInBase.defaultProps = {
  divider: false
};
export default SignInBase;