import { useState } from 'react';
import _ from 'lodash';
import { authorize } from 'services/auth';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import Input from 'components/Input/Input';
import LoaderButton from 'components/Button/LoaderButton';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import MainContainer from 'components/Container/MainContainer';
import { AuthProcessingResponse } from '@finch-api/common/dist/internal/connect/authorize';
import WarningMessage from '../Messages/Warning';
import styled from 'styled-components';
import SignInDisclaimer from 'components/Text/SignInDisclaimer';
import { getStatusCode } from '../SignIn/utils';
import { ProviderAppShell } from '../ProviderAppShell';
import { FinchErrorCode } from 'constants/finch-error-code';
const MfaText = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  width: 100%;
`;
const Mfa = ({
  client,
  clientId,
  connectionId,
  clientName,
  redirectUri,
  products,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  payrollProvider,
  providerImplementation,
  prevStep,
  phoneNumber,
  email,
  mfaType,
  challengeQuestion,
  handleAuthorizeResponse,
  sandbox,
  appType,
  error,
  setError,
  setProviderToRedirect,
  authorizeLoading,
  setAuthorizeLoading,
  mfaReason,
  implementationHasAssistedBenefits
}: AuthorizeContextValue) => {
  if (!client) throw new Error('no client');
  if (!payrollProvider) throw new Error('no payroll provider');
  if (!providerImplementation) throw new Error('no provider implementation');
  const [disableSubmit, setDisableSubmit] = useState(false);
  // The form key is used to reset the form when the user submits and the api-server
  // returns a next of MFA. This will cause the form to re-render and clear the input
  // field for the next MFA page.
  const [formKey, setFormKey] = useState(0);
  const onContinue = async (fieldToValueMap: Record<string, string>) => {
    setAuthorizeLoading(true);
    setError(null);
    const {
      mfaCode
    } = fieldToValueMap;
    try {
      const authorizeResponse = (await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        mfaCode,
        sessionKey,
        currentBrowserSessionKey,
        state,
        category,
        step: 'MFA',
        payrollProviderId: payrollProvider.id,
        providerImplementationId: providerImplementation.id,
        sandbox,
        appType,
        implementationHasAssistedBenefits
      })) as AuthProcessingResponse;
      if (authorizeResponse.next === 'MFA') {
        setFormKey(formKey + 1);
      }
      handleAuthorizeResponse(authorizeResponse);

      // If we are redirecting, we keep the loading state to avoid double submission.
      // Therefore, we only remove the loading state when there is no redirect.
      if (!('redirect' in authorizeResponse)) {
        setAuthorizeLoading(false);
      }
    } catch (err: any) {
      setAuthorizeLoading(false);
      const finchCode = _.get(err, 'response.data.finch_code');
      if ([FinchErrorCode.AccountSetupRequired, FinchErrorCode.NoValidAccounts, FinchErrorCode.SessionExpired, FinchErrorCode.IncorrectPayrollProvider].includes(finchCode)) {
        setDisableSubmit(true);
      }
      if (finchCode === FinchErrorCode.IncorrectPayrollProvider) {
        const correctPayrollProvider = _.get(err, 'response.data.correct_payroll_provider');
        setProviderToRedirect(correctPayrollProvider);
      }
      if (err.message === 'Incorrect MFA code. Max attempts reached. Please try again from the beginning.') {
        setError({
          message: err.message,
          status: getStatusCode(err)
        });
        prevStep();
      }
      setError({
        message: err.message || 'Unexpected error has occurred. Please try again.',
        status: getStatusCode(err),
        finchCode
      });
    }
  };
  const getMfaText = () => {
    switch (mfaType) {
      case 'text_message':
      case 'voice':
        {
          if (phoneNumber) return `Enter the code sent to ${phoneNumber}`;
          return 'Enter the code sent to your phone';
        }
      case 'authenticator_app':
        return 'Enter the code from the authenticator app';
      case 'email':
        {
          if (email) return `Enter the code sent to ${email}`;
          return 'Enter the code sent to your email';
        }
      case 'question':
        return challengeQuestion;
      default:
        return 'Enter the code sent to your phone';
    }
  };
  const getMfaReason = () => {
    switch (mfaReason) {
      case 'ADD_ACCOUNTANT':
        return `This secondary MFA is required to add an additional user to your account. `;
      case 'GET_API_TOKEN':
        return `A second MFA code is required to securely connect. Please wait for the new code to generate, which may take up to 30 seconds. Once received, enter the new code below.`;
      default:
        return null;
    }
  };
  const mfaText = getMfaText();
  const mfaReasonText = getMfaReason();
  const headerText = mfaReasonText ? 'Additional MFA Required' : mfaText;
  return <ProviderAppShell error={error} setError={setError} provider={payrollProvider} providerImplementation={providerImplementation} goBack={() => prevStep()} headerText={headerText} data-sentry-element="ProviderAppShell" data-sentry-component="Mfa" data-sentry-source-file="Mfa.tsx">
      {mfaReasonText && <WarningMessage>
          {mfaReasonText}
          {payrollProvider.id === 'paycom' ? <b>Please wait up to 5 minutes after submission.</b> : ''}
        </WarningMessage>}
      {/* Even if we don't show the additional MFA, we still want to show wait up to 5 minutes */}
      {!mfaReasonText && payrollProvider.id === 'paycom' ? <WarningMessage>
          <b>Please wait up to 5 minutes after submission.</b>
        </WarningMessage> : null}
      {mfaReasonText && <MfaText>{mfaText}</MfaText>}
      <MultiValueForm formLabel="MFA Form" formKey={formKey} onSubmit={onContinue} fieldNames={['mfaCode']} render={({
      mfaCode
    }) => <>
            <MainContainer>
              <Input id="mfaCode" value={mfaCode.value} onChange={event => mfaCode.onChange(event.target.value)} invalid={mfaCode.invalid} label={mfaType === 'question' ? 'Answer' : 'Code'} autoFocus />
              {payrollProvider.id === 'quickbooks' && <SignInDisclaimer>
                  Keep the window open while we sign into your Quickbooks
                  account. This could take a few minutes.
                </SignInDisclaimer>}
            </MainContainer>
            <LoaderButton color={payrollProvider.primaryColor} isLoading={authorizeLoading} disabled={authorizeLoading || disableSubmit} type="submit">
              Continue
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="Mfa.tsx" />
    </ProviderAppShell>;
};
export default withAuthorizeContext(Mfa);