export const SCROLL_SHADOW = {
  background: `linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center top,
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center bottom;`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 20px, 100% 20px, 100% 8px, 100% 8px',
  backgroundAttachment: 'local, local, scroll, scroll',
};
